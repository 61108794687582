<template>
  <b-autocomplete
    ref="autocomplete"
    v-model="inputValue"
    placeholder="Select a connection"
    :open-on-focus="true"
    :data="filteredRecords"
    :custom-formatter="customFormatter"
    :loading="fetchingConnections || isLoading"
    @select="onSelect"
  >
    <template slot-scope="props">
      {{ props.option.name_zh }}
      <br />
      {{ props.option.name_en }}
      <br />
      <template v-if="props.option.club[0]">
        {{ props.option.club[0].church_id.name_zh }}
      </template>
      <template v-else-if="props.option.church[0]">
        {{ props.option.church[0].name_zh }}
      </template>
      <template v-else>
        <i>No church or club record</i>
      </template>
    </template>
  </b-autocomplete>
</template>

<style scoped lang="less"></style>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ConnectionSelect",
  props: ["value", "skipGetConnection"],
  data() {
    return {
      records: [],
      isLoading: false,
      inputValue: this.value,
    };
  },
  computed: {
    ...mapState(["connections", "fetchingConnections"]),
    filteredRecords: function () {
      if (this.inputValue === undefined || typeof this.value === "number") {
        return Object.values(this.connections);
      } else {
        return Object.values(this.connections).filter((record) => {
          return (
            record.name_zh
              .toString()
              .indexOf(this.inputValue.toString().trim()) >= 0 ||
            record.name_en
              .toString()
              .toLowerCase()
              .indexOf(this.inputValue.toString().trim().toLowerCase()) >= 0
          );
        });
      }
    },
  },
  watch: {
    value: function () {
      this.selectById();
    },
    // connections: function () {
    //   this.selectById();
    // },
  },
  mounted() {
    if (!this.skipGetConnection) {
      this.getConnections();
    }
    this.selectById();
  },
  methods: {
    ...mapActions(["getConnections"]),
    selectById() {
      this.$emit("selectByIdStart");
      this.isLoading = true;
      this.$refs.autocomplete.setSelected(
        this.connections[parseInt(this.value)]
      );
      this.isLoading = false;
      this.$emit("selectByIdEnd");
    },
    customFormatter(record) {
      return [record.name_zh, record.name_en].join(" ");
    },
    onSelect(option) {
      if (option === null) {
        this.$emit("input", undefined);
      } else {
        this.$emit("input", option.id);
      }
    },
  },
};
</script>
