<template>
  <div>
    <Navbar active="churches" />
    <CrudView
      tableName="church"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
    >
      <template>
        <div class="box" style="position: relative">
          <b-loading
            :is-full-page="false"
            v-model="fetchingClubs"
            v-if="fetchingClubs"
          ></b-loading>
          <div clss="data-container">
            <span class="data-label">Clubs</span>
            <span class="data-content">
              <div v-for="club in clubs" :key="club.id">
                <router-link
                  :to="{
                    name: 'ClubView',
                    params: { id: club.id },
                  }"
                >
                  {{ [club.reg_num, club.name_zh, club.name_en].join(" ") }}
                </router-link>
              </div>
            </span>
          </div>
        </div>

        <div class="box" style="position: relative">
          <b-loading
            :is-full-page="false"
            v-model="fetchingPosts"
            v-if="fetchingPosts"
          ></b-loading>
          <div clss="data-container">
            <span class="data-label">Connections</span>
            <span class="data-content">
              <div v-for="post in posts" :key="post.id">
                <template v-if="post.title != ''">
                  {{ post.title }} -
                </template>
                <router-link
                  :to="{
                    name: 'ConnectionView',
                    params: { id: post.connection_id.id },
                  }"
                >
                  {{
                    [
                      post.connection_id.name_zh,
                      post.connection_id.name_en,
                    ].join(" ")
                  }}
                </router-link>
                <template v-if="post.connection_id.notes != ''">
                  ({{ post.connection_id.notes }})
                </template>
              </div>
            </span>
          </div>
        </div>
      </template>
    </CrudView>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudView from "@/components/CrudView.vue";

export default {
  name: "ChurchView",
  components: {
    Navbar,
    CrudView,
  },
  data() {
    return {
      fetchingClubs: true,
      clubs: [],
      fetchingPosts: true,
      posts: [],
      isLoading: true,
      fields: [
        {
          field: "id",
          label: "ID",
        },
        {
          field: "name_zh",
          label: "Name (Chinese)",
        },
        {
          field: "name_en",
          label: "Name (English)",
        },
        {
          field: "addr",
          label: "Address",
        },
        {
          field: "district",
          label: "District",
        },
        {
          field: "phone",
          label: "Phone",
        },
        {
          field: "email",
          label: "Email",
        },
      ],
    };
  },
  mounted() {
    this.fetchingClubs = true;
    this.axios
      .get(
        "https://ems.awana.org.hk/api/api.php/records/church/" +
          this.$route.params.id +
          "?join=club",
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.clubs = response.data.club;
        this.fetchingClubs = false;
      });
    this.axios
      .get(
        "https://ems.awana.org.hk/api/api.php/records/church/" +
          this.$route.params.id +
          "?join=post,connection",
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.posts = response.data.post;
        var postTitleOrder = ["營牧", "牧者", "總指揮", "秘書", "營秘書"];
        this.posts.sort((a, b) => {
          if (a.title !== "" && b.title !== "") {
            if (postTitleOrder.indexOf(a.title) === -1) {
              return 1;
            } else if (postTitleOrder.indexOf(b.title) === -1) {
              return -1;
            }
            return (
              postTitleOrder.indexOf(a.title) - postTitleOrder.indexOf(b.title)
            );
          } else if (a.title !== "") {
            return -1;
          } else if (b.title !== "") {
            return 1;
          } else {
            return a.connection_id.name_en > b.connection_id.name_en;
          }
        });
        this.fetchingPosts = false;
      });
  },
  methods: {
    titleRenderer(data) {
      var titleString = data.name_zh;
      if (data.name_zh !== "" && data.name_en !== "") {
        titleString += "<br/>";
      }
      titleString += data.name_en;
      return titleString;
    },
    dataModifier(data) {
      data.district = data.district
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.substr(1))
        .join(" ");
      return data;
    },
  },
};
</script>
