<template>
  <div>
    <Navbar active="admin_settings" />
    <div class="section">
      <div class="container">
        <h1>Site Settings</h1>
        <div class="columns">
          <div class="column">
            <div class="box">
              <span class="box-title">Backups</span>
              x
            </div>
          </div>
          <div class="column"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.box {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: none;
}

.box-title {
  font-size: 22px;
  display: block;
  margin-bottom: 20px;
}
</style>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "AdminSettings",
  components: {
    Navbar,
  },
};
</script>
