<template>
  <div>
    <Navbar active="dashboard" />
    <div class="section">
      <div class="container">
        <h1>Hey, {{ user.username }}</h1>
        <div class="box">There's nothing here yet :'(</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.box {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: none;
}
</style>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";

export default {
  name: "Dashboard",
  components: {
    Navbar,
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
