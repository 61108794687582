import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "../views/NotFound.vue";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import ChurchList from "../views/Churches/ChurchList.vue";
import ChurchNew from "../views/Churches/ChurchNew.vue";
import ChurchView from "../views/Churches/ChurchView.vue";
import ChurchEdit from "../views/Churches/ChurchEdit.vue";
import ClubList from "../views/Clubs/ClubList.vue";
import ClubNew from "../views/Clubs/ClubNew.vue";
import ClubView from "../views/Clubs/ClubView.vue";
import ClubEdit from "../views/Clubs/ClubEdit.vue";
import ConnectionList from "../views/Connections/ConnectionList.vue";
import ConnectionNew from "../views/Connections/ConnectionNew.vue";
import ConnectionView from "../views/Connections/ConnectionView.vue";
import ConnectionEdit from "../views/Connections/ConnectionEdit.vue";
import TrainingList from "../views/Trainings/TrainingList.vue";
import TrainingNew from "../views/Trainings/TrainingNew.vue";
import TrainingView from "../views/Trainings/TrainingView.vue";
import TrainingEdit from "../views/Trainings/TrainingEdit.vue";
import AdminLogList from "../views/AdminLogs/AdminLogList.vue";
import AdminUserList from "../views/AdminUsers/AdminUserList.vue";
import AdminUserNew from "../views/AdminUsers/AdminUserNew.vue";
import AdminUserView from "../views/AdminUsers/AdminUserView.vue";
import AdminSettings from "../views/AdminSettings/AdminSettings.vue";
import UtilitiesMailingList from "../views/UtilitiesMailingList/UtilitiesMailingList.vue";
import store from "../store";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/church",
    name: "ChurchList",
    component: ChurchList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/church/new",
    name: "ChurchNew",
    component: ChurchNew,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/church/:id",
    name: "ChurchView",
    component: ChurchView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/church/:id/edit",
    name: "ChurchEdit",
    component: ChurchEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/club",
    name: "ClubList",
    component: ClubList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/club/new",
    name: "ClubNew",
    component: ClubNew,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/club/:id",
    name: "ClubView",
    component: ClubView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/club/:id/edit",
    name: "ClubEdit",
    component: ClubEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/connection",
    name: "ConnectionList",
    component: ConnectionList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/connection/new",
    name: "ConnectionNew",
    component: ConnectionNew,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/connection/:id",
    name: "ConnectionView",
    component: ConnectionView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/connection/:id/edit",
    name: "ConnectionEdit",
    component: ConnectionEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/training",
    name: "TrainingList",
    component: TrainingList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/training/new",
    name: "TrainingNew",
    component: TrainingNew,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/training/:id",
    name: "TrainingView",
    component: TrainingView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/training/:id/edit",
    name: "TrainingEdit",
    component: TrainingEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/utilities_mailing_list",
    name: "UtilitiesMailingList",
    component: UtilitiesMailingList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin_log",
    name: "AdminLogList",
    component: AdminLogList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin_user",
    name: "AdminUserList",
    component: AdminUserList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin_user/new",
    name: "AdminUserNew",
    component: AdminUserNew,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin_user/:id",
    name: "AdminUserView",
    component: AdminUserView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin_settings",
    name: "AdminSettings",
    component: AdminSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV !== "development") {
    axios
      .post(
        "https://ems.awana.org.hk/api/api.php/records/admin_log",
        {
          user_id: store.state.user.id,
          event: "page_view",
          content: JSON.stringify({
            routePath: to.path,
            routeFullPath: to.fullPath,
            routeName: to.name,
          }),
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if ("id" in store.state.user) {
      next(); // go to wherever I'm going
    } else {
      next({ name: "Login" });
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
