<template>
  <div>
    <Navbar active="admin_users" />
    <CrudNew
      tableName="admin_user"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudNew from "@/components/CrudNew.vue";
import bcrypt from "bcryptjs";

export default {
  label: "AdminUserNew",
  components: {
    Navbar,
    CrudNew,
  },
  data() {
    return {
      fields: [
        {
          field: "username",
          label: "Username",
        },
        {
          field: "password",
          label: "Password",
          type: "password",
        },
        {
          field: "role",
          label: "Role",
          type: "user-role",
        },
      ],
    };
  },
  methods: {
    titleRenderer(fieldValues) {
      var titleString = "New User: " + fieldValues.username;
      return titleString;
    },
    dataModifier(fieldValues) {
      if (
        fieldValues.username === "" ||
        fieldValues.password === "" ||
        fieldValues.role === ""
      ) {
        return "Please fill in all fields";
      }
      fieldValues.password = bcrypt.hashSync(
        fieldValues.password,
        bcrypt.genSaltSync(10)
      );
      return fieldValues;
    },
  },
};
</script>
