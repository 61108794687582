<template>
  <div class="modal-card" style="border-radius: 4px">
    <div class="modal-card-body">
      <h2 class="mb-1" style="font-size: 24px">Confirm record deletion</h2>
      <p>
        Are you sure that you want to delete
        {{ this.recordLabel(recordTable, recordData) }}?
      </p>
      <p v-if="Object.keys(childRecords).length !== 0">
        The following records will also be deleted as well.
      </p>
      <!-- <p v-else>(there are none other records related)</p> -->
      <b-collapse
        v-if="Object.keys(childRecords).length !== 0"
        :open="false"
        position="is-top"
        aria-id="contentIdForA11y1"
      >
        <template #trigger="props">
          <a aria-controls="contentIdForA11y1">
            {{ !props.open ? "Show" : "Hide" }}
            <b-icon
              :icon="!props.open ? 'chevron-down' : 'chevron-up'"
            ></b-icon>
          </a>
        </template>
        <div
          v-for="(childRecord, recordType) in childRecords"
          :key="recordType"
          class="mt-3"
        >
          {{ recordType }}:
          <div v-for="record in childRecord" :key="record">
            {{ record }}
          </div>
        </div>
      </b-collapse>

      <div class="buttons mt-5 is-pulled-right">
        <b-button type="is-danger is-light" @click="$parent.close()"
          >Cancel</b-button
        >
        <b-button type="is-danger" :loading="deleting" @click="confirmDelete"
          >Confirm deletion</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import recordLabelsMixin from "@/mixins/recordLabelsMixin";

export default {
  name: "DeleteRecordModal",
  mixins: [recordLabelsMixin],
  props: {
    recordTable: {
      type: String,
    },
    recordData: {
      type: Object,
    },
    // childTables: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // },
  },
  data() {
    return {
      childRecords: {},
      deleting: false,
    };
  },
  computed: {
    childTables() {
      switch (this.recordTable) {
        case "church":
          return "?join=club,post,connection&join=post,connection";
        case "club":
          return "?join=post,connection";
        case "connection":
          return "?join=post,club&join=enrollment,training";
        case "post":
          return [];
        case "training":
          return "?join=enrollment,connection"; // TODO
        case "enrollment":
          return [];
        default:
          return [];
      }
    },
  },
  mounted() {
    // fetch record, joined with all child tables
    // go through all child records, and child records of child records
    // add record labels to display

    this.axios
      .get(
        "https://ems.awana.org.hk/api/api.php/records/" +
          this.recordTable +
          "/" +
          this.recordData.id +
          this.childTables,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // this.isLoading = false;
        console.log(response.data);
        switch (this.recordTable) {
          case "church":
            response.data.club.forEach((club) => {
              if ("club" in this.childRecords) {
                this.childRecords.club.push(this.recordLabel("club", club));
              } else {
                this.childRecords.club = [this.recordLabel("club", club)];
              }

              club.post.forEach((post) => {
                post.club_id = club;
                if ("post" in this.childRecords) {
                  this.childRecords.post.push(
                    this.recordLabel("post", post, true)
                  );
                } else {
                  this.childRecords.post = [
                    this.recordLabel("post", post, true),
                  ];
                }
              });
            });

            response.data.post.forEach((post) => {
              post.church_id = this.recordData;
              if ("post" in this.childRecords) {
                this.childRecords.post.push(
                  this.recordLabel("post", post, true)
                );
              } else {
                this.childRecords.post = [this.recordLabel("post", post, true)];
              }
            });
            break;
          case "club":
            response.data.post.forEach((post) => {
              post.club_id = this.recordData;
              if ("post" in this.childRecords) {
                this.childRecords.post.push(
                  this.recordLabel("post", post, true)
                );
              } else {
                this.childRecords.post = [this.recordLabel("post", post, true)];
              }
            });
            break;
          case "connection":
            response.data.post.forEach((post) => {
              post.connection_id = this.recordData;
              if ("post" in this.childRecords) {
                this.childRecords.post.push(
                  this.recordLabel("post", post, true)
                );
              } else {
                this.childRecords.post = [this.recordLabel("post", post, true)];
              }
            });
            response.data.enrollment.forEach((enrollment) => {
              enrollment.connection_id = this.recordData;
              if ("enrollment" in this.childRecords) {
                this.childRecords.enrollment.push(
                  this.recordLabel("enrollment", enrollment, true)
                );
              } else {
                this.childRecords.enrollment = [
                  this.recordLabel("enrollment", enrollment, true),
                ];
              }
            });
            break;
          case "post":
            break;
          case "training":
            response.data.enrollment.forEach((enrollment) => {
              enrollment.training_id = this.recordData;
              if ("enrollment" in this.childRecords) {
                this.childRecords.enrollment.push(
                  this.recordLabel("enrollment", enrollment, true)
                );
              } else {
                this.childRecords.enrollment = [
                  this.recordLabel("enrollment", enrollment, true),
                ];
              }
            });
            break;
          case "enrollment":
            break;
        }
        this.$forceUpdate();
      });
  },
  methods: {
    confirmDelete() {
      this.deleting = true;
      this.axios
        .delete(
          "https://ems.awana.org.hk/api/api.php/records/" +
            this.recordTable +
            "/" +
            this.recordData.id,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          this.$emit("deleted");
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.deleting = false;
          this.$parent.close();
        });
    },
  },
};
</script>

<style lang="less" scoped></style>
