<template>
  <div>
    <Navbar active="trainings" />
    <CrudView
      tableName="training"
      :isHorizontal="true"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
    >
      <template>
        <div class="box" style="position: relative">
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            v-if="fetchingEnrollments"
          ></b-loading>
          <div clss="data-container">
            <span class="data-label">Enrollments</span>
            <b-table :data="enrollments">
              <b-table-column
                field="result"
                label="Result"
                v-slot="props"
                :sortable="true"
              >
                {{ props.row.result }}
              </b-table-column>
              <b-table-column
                field="connection_id.name_zh"
                label="Name (Chinese)"
                v-slot="props"
                :sortable="true"
              >
                <router-link
                  :to="{
                    name: 'ConnectionView',
                    params: { id: props.row.connection_id.id },
                  }"
                >
                  {{ props.row.connection_id.name_zh }}
                </router-link>
              </b-table-column>
              <b-table-column
                field="connection_id.name_en"
                label="Name (English)"
                v-slot="props"
                :sortable="true"
              >
                <router-link
                  :to="{
                    name: 'ConnectionView',
                    params: { id: props.row.connection_id.id },
                  }"
                >
                  {{ props.row.connection_id.name_en }}
                </router-link>
              </b-table-column>
              <b-table-column field="post" label="Post" v-slot="props">
                <template v-for="post in props.row.connection_id.post">
                  <p
                    v-if="post.club_id !== undefined && post.club_id !== null"
                    :key="post.id"
                  >
                    {{ post.title }} @
                    <router-link
                      :to="{
                        name: 'ClubView',
                        params: { id: post.club_id.id },
                      }"
                    >
                      {{ post.club_id.reg_num }} {{ post.club_id.name_zh }}
                    </router-link>
                  </p>
                  <p
                    v-else-if="
                      post.church_id !== undefined && post.church_id !== null
                    "
                    :key="post.id"
                  >
                    {{ post.title }} @
                    <router-link
                      :to="{
                        name: 'ChurchView',
                        params: { id: post.church_id.id },
                      }"
                    >
                      {{ post.church_id.name_zh }}
                    </router-link>
                  </p>
                </template>
              </b-table-column>
              <template #empty>
                <div class="has-text-centered">No records</div>
              </template>
            </b-table>
            <!-- <div class="data-content">
              <div v-for="enrollment in enrollments" :key="enrollment.id">
                {{ enrollment.result }} -
                <router-link
                  :to="{
                    name: 'ConnectionView',
                    params: { id: enrollment.connection_id.id },
                  }"
                >
                  {{
                    [
                      enrollment.connection_id.name_zh,
                      enrollment.connection_id.name_en,
                    ].join(" ")
                  }}
                </router-link>
                <template v-if="enrollment.notes != ''">
                  ({{ enrollment.notes }})
                </template>
              </div>
            </div> -->
          </div>
        </div>
      </template>
    </CrudView>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudView from "@/components/CrudView.vue";

export default {
  name: "TrainingView",
  components: {
    Navbar,
    CrudView,
  },
  data() {
    return {
      fetchingEnrollments: true,
      enrollments: [],
      isLoading: true,
      fields: [
        { field: "id", label: "ID" },
        { field: "course_code", label: "Code", type: "input" },
        { field: "course_title", label: "Title", type: "input" },
        { field: "course_date", label: "Date", type: "input" },
        { field: "course_level", label: "Level", type: "input" },
      ],
    };
  },
  mounted() {
    this.fetchingPosts = true;
    this.fetchingEnrollments = true;
    this.axios
      .get(
        "https://ems.awana.org.hk/api/api.php/records/training/" +
          this.$route.params.id +
          "?join=enrollment,connection,post,club&join=enrollment,connection,post,church",
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data.enrollment);
        this.enrollments = response.data.enrollment;
        this.fetchingEnrollments = false;
      });
  },
  methods: {
    titleRenderer(data) {
      return [data.course_code, data.course_title].join(" ");
    },
    dataModifier(data) {
      return data;
    },
  },
};
</script>
