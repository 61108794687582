<template>
  <div>
    <Navbar active="clubs" />
    <CrudView
      tableName="club"
      queryParams="?join=church"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
      @record-ready="clubRecordReady"
    >
      <template v-slot:below-all>
        <div class="box" style="position: relative; margin-top: -0.75rem">
          <span class="data-label"
            >Registration Details (collected from
            {{ clubRegistrationRecord.renewal_for_year }} Renewal)</span
          >
          Registration fee discount:
          {{ clubRegistrationRecord["registration-fee-discount"] }}
          <table
            class="table"
            v-if="'cubbies-yr1-this' in clubRegistrationRecord"
          >
            <tr class="table-row-center">
              <td rowspan="2">Club(s)</td>
              <td rowspan="2">Puggles</td>
              <td colspan="2">Cubbies</td>
              <td colspan="3">Sparks</td>
              <td colspan="2">T&amp;T UA</td>
              <td colspan="2">T&amp;T UC</td>
              <td rowspan="2">Trek</td>
              <td rowspan="2">Journey</td>
            </tr>
            <tr class="table-row-center">
              <td>1st yr</td>
              <td>2nd yr</td>
              <td>HG</td>
              <td>WR</td>
              <td>SS</td>
              <td>BK 1</td>
              <td>BK 2</td>
              <td>BK 1</td>
              <td>BK 2</td>
            </tr>
            <tr>
              <td>No. of enrollment</td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["puggles-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["cubbies-yr1-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["cubbies-yr2-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-hg-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-wr-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-ss-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-ua-bk1-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-ua-bk2-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-uc-bk1-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-uc-bk2-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["trek-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["journey-this"]
                }}</span>
              </td>
            </tr>
            <tr>
              <td>No. of leaders</td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["puggles-leader-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["cubbies-yr1-leader-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["cubbies-yr2-leader-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-hg-leader-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-wr-leader-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-ss-leader-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-ua-bk1-leader-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-ua-bk2-leader-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-uc-bk1-leader-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-uc-bk2-leader-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["trek-leader-this"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["journey-leader-this"]
                }}</span>
              </td>
            </tr>
            <tr>
              <td>Expected No. of enrollment</td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["puggles-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["cubbies-yr1-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["cubbies-yr2-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-hg-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-wr-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-ss-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-ua-bk1-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-ua-bk2-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-uc-bk1-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-uc-bk2-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["trek-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["journey-next"]
                }}</span>
              </td>
            </tr>
            <tr>
              <td>Expected No. of leaders</td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["puggles-leader-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["cubbies-yr1-leader-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["cubbies-yr2-leader-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-hg-leader-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-wr-leader-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["sparks-ss-leader-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-ua-bk1-leader-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-ua-bk2-leader-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-uc-bk1-leader-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["tnt-uc-bk2-leader-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["trek-leader-next"]
                }}</span>
              </td>
              <td>
                <span class="yearly-renewal-field-ans">{{
                  clubRegistrationRecord["journey-leader-next"]
                }}</span>
              </td>
            </tr>
          </table>
          <table class="table" v-else>
            <tr>
              <td>Club(s)</td>
              <td>Puggles</td>
              <td>Cubbies</td>
              <td>Sparks</td>
              <td>T&amp;T UA</td>
              <td>T&amp;T UC</td>
              <td>Trek</td>
              <td>Journey</td>
            </tr>
            <tr>
              <td>No. of enrollment</td>
              <td>
                <span>{{ clubRegistrationRecord["puggles-this"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["cubbies-this"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["sparks-this"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["tnt-ua-this"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["tnt-uc-this"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["trek-this"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["journey-this"] }}</span>
              </td>
            </tr>
            <tr>
              <td>Expected No. of enrollment</td>
              <td>
                <span>{{ clubRegistrationRecord["puggles-next"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["cubbies-next"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["sparks-next"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["tnt-ua-next"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["tnt-uc-next"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["trek-next"] }}</span>
              </td>
              <td>
                <span>{{ clubRegistrationRecord["journey-next"] }}</span>
              </td>
            </tr>
          </table>
          Date to start:
          {{ clubRegistrationRecord["start-date"] }}<br />
          Total # of leaders / helpers:
          {{ clubRegistrationRecord["leader-num"] }}<br />
          Day of meeting:
          {{ clubRegistrationRecord["meeting-day"] }}<br />
          Time of meeting:
          {{ clubRegistrationRecord["meeting-time-from-hr"] }}:{{
            clubRegistrationRecord["meeting-time-from-min"]
          }}
          - {{ clubRegistrationRecord["meeting-time-to-hr"] }}:{{
            clubRegistrationRecord["meeting-time-to-min"]
          }}
        </div>
      </template>
      <template>
        <div class="box" style="position: relative">
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            v-if="fetchingPosts"
          ></b-loading>
          <div clss="data-container">
            <span class="data-label">Connections</span>
            <span class="data-content">
              <div v-for="post in posts" :key="post.id">
                <template v-if="post.title != ''">
                  {{ post.title }} -
                </template>
                <router-link
                  :to="{
                    name: 'ConnectionView',
                    params: { id: post.connection_id.id },
                  }"
                >
                  {{
                    [
                      post.connection_id.name_zh,
                      post.connection_id.name_en,
                    ].join(" ")
                  }}
                </router-link>
                <template v-if="post.connection_id.notes != ''">
                  ({{ post.connection_id.notes }})
                </template>
              </div>
            </span>
          </div>
        </div>
      </template>
    </CrudView>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudView from "@/components/CrudView.vue";

export default {
  name: "ClubView",
  components: {
    Navbar,
    CrudView,
  },
  data() {
    return {
      fetchingPosts: true,
      posts: [],
      isLoading: true,
      fields: [
        { field: "id", label: "ID" },
        { field: "reg_num", label: "Registration #" },
        { field: "reg_date", label: "Registration Date" },
        { field: "church_id", label: "Church", type: "church" },
        { field: "name_zh", label: "Name (Chinese)" },
        { field: "name_en", label: "Name (English)" },
        { field: "phone", label: "Phone" },
        { field: "email", label: "Email" },
        { field: "existing", label: "Existing" },
        { field: "notes", label: "Notes" },
      ],
      clubRegistrationRecord: {},
    };
  },
  mounted() {
    this.fetchingPosts = true;
    this.axios
      .get(
        "https://ems.awana.org.hk/api/api.php/records/club/" +
          this.$route.params.id +
          "?join=post,connection",
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.posts = response.data.post;
        var postTitleOrder = ["營牧", "牧者", "總指揮", "秘書", "營秘書"];
        this.posts.sort((a, b) => {
          if (a.title !== "" && b.title !== "") {
            if (postTitleOrder.indexOf(a.title) === -1) {
              return 1;
            } else if (postTitleOrder.indexOf(b.title) === -1) {
              return -1;
            }
            return (
              postTitleOrder.indexOf(a.title) - postTitleOrder.indexOf(b.title)
            );
          } else if (a.title !== "") {
            return -1;
          } else if (b.title !== "") {
            return 1;
          } else {
            return a.connection_id.name_en > b.connection_id.name_en;
          }
        });
        this.fetchingPosts = false;
      });
  },
  methods: {
    titleRenderer(data) {
      return data.reg_num;
    },
    dataModifier(data) {
      return data;
    },
    clubRecordReady(record) {
      console.log();
      this.axios
        .get(
          "https://ems.awana.org.hk/functions/fetch-church-registration-details.php?club_id=" +
            record.reg_num,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          this.clubRegistrationRecord = response.data;
        });
    },
  },
};
</script>
