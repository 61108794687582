export default {
  methods: {
    capitalize(string) {
      if (string === undefined || string === null) {
        return "";
      }
      string = string.toString();
      string = string.replace("_", " ");
      return string.charAt(0).toUpperCase() + string.substr(1);
    },
    capitalizeAll(string) {
      if (string === undefined || string === null) {
        return "";
      }
      string = string.replace("_", " ");
      var words = string.split(" ");
      return words
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.substr(1);
        })
        .join(" ");
    },
  },
};
