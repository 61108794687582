<template>
  <div>
    <Navbar />
    <div class="section">
      <div class="container">
        <img src="@/assets/images/undraw_Taken_re_yn20.png" />
        <span class="not-found-title">404 Page Not Found</span>
        <span class="not-found-text">You're not supposed to be here!</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.container {
  text-align: center;
}

img {
  max-width: 350px;
  height: auto;
  margin-top: 15px;
}

.not-found-title {
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  display: block;
  margin-top: 40px;
}

.not-found-text {
  font-size: 22px;
  display: block;
}
</style>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

export default {
  name: "NotFound",
  components: {
    Navbar,
  },
};
</script>
