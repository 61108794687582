<template>
  <div>
    <Navbar active="churches" />
    <CrudEdit
      tableName="church"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
    >
      <template>
        <div class="box" ref="postBox" style="position: relative">
          <div class="label">Posts</div>
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            v-if="
              fetchingPosts || fetchingConnections || connections.length === 0
            "
          ></b-loading>
          <form v-else @submit.prevent="submitPosts">
            <div v-for="(post, index) in posts" :key="index" class="rows">
              <div
                v-if="post.type !== 'delete'"
                class="columns is-variable is-1"
              >
                <template v-if="post.editing">
                  <div class="column">
                    <ConnectionSelect
                      @input="modifyPost(index)"
                      v-model="post.connection_id"
                      @selectByIdStart="settingConnections = true"
                      @selectByIdEnd="settingConnections = false"
                    />
                  </div>
                  <div class="column">
                    <b-input
                      @input="modifyPost(index)"
                      placeholder="Title"
                      v-model="post.title"
                    ></b-input>
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      @click="posts[index].editing = false"
                      type="is-success is-light"
                      icon-right="check"
                    />
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      @click="cancelEditPost(index)"
                      type="is-danger is-light"
                      icon-right="times"
                    />
                  </div>
                </template>
                <template v-else>
                  <div class="column">
                    {{ connections[post.connection_id].name_zh }}
                    {{ connections[post.connection_id].name_en }}
                  </div>
                  <div class="column">
                    {{ post.title }}
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      @click="editPost(index)"
                      type="is-primary is-light"
                      icon-right="pen"
                    />
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      @click="removePost(index)"
                      type="is-danger is-light"
                      icon-right="trash"
                    />
                  </div>
                </template>
              </div>
            </div>
            <div class="buttons">
              <b-button @click="addPost" expanded>Add Post</b-button>
              <b-button
                type="is-primary"
                native-type="submit"
                :loading="isSubmitting"
                >Update Posts</b-button
              >
            </div>
          </form>
        </div>
      </template>
    </CrudEdit>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudEdit from "@/components/CrudEdit.vue";
import ConnectionSelect from "@/components/ConnectionSelect.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  label: "ChurchEdit",
  components: {
    Navbar,
    CrudEdit,
    ConnectionSelect,
  },
  data() {
    return {
      fetchingPosts: true,
      posts: [],
      originalPosts: [],
      isLoading: true,
      isSubmitting: false,
      settingConnections: false,
      editPostsActive: false,
      fields: [
        {
          field: "name_zh",
          label: "Name (Chinese)",
        },
        {
          field: "name_en",
          label: "Name (English)",
        },
        {
          field: "addr",
          label: "Address",
        },
        {
          field: "district",
          label: "District",
          type: "district",
        },
        {
          field: "phone",
          label: "Phone",
        },
        {
          field: "email",
          label: "Email",
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "connections",
      "fetchingConnections",
      "suppressFetchConnections",
    ]),
  },
  mounted() {
    this.getConnections();
    this.fetchingPosts = true;
    this.axios
      .get(
        "https://ems.awana.org.hk/api/api.php/records/post?filter=church_id,eq," +
          this.$route.params.id,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        const localPosts = response.data.records;
        var postTitleOrder = ["營牧", "牧者", "總指揮", "秘書", "營秘書"];
        localPosts.sort((a, b) => {
          if (a.title !== "" && b.title !== "") {
            if (postTitleOrder.indexOf(a.title) === -1) {
              return 1;
            } else if (postTitleOrder.indexOf(b.title) === -1) {
              return -1;
            }
            return (
              postTitleOrder.indexOf(a.title) - postTitleOrder.indexOf(b.title)
            );
          } else if (a.title !== "") {
            return -1;
          } else if (b.title !== "") {
            return 1;
          } else {
            return a.connection_id.name_en > b.connection_id.name_en;
          }
        });
        localPosts.forEach((post) => {
          post.type = "existing";
          post.editing = false;
        });
        this.posts = localPosts;
        this.fetchingPosts = false;
      });
  },
  methods: {
    ...mapActions(["getConnections"]),
    ...mapMutations(["setSuppressFetchConnections"]),
    titleRenderer(fieldValues) {
      var titleString =
        "Edit Church:<br />" +
        fieldValues.name_zh +
        "<br />" +
        fieldValues.name_en;
      return titleString;
    },
    dataModifier(fieldValues) {
      if (fieldValues.name_zh === "" && fieldValues.name_en === "") {
        return "A church must at least have a name in Chinese or English";
      }
      return fieldValues;
    },
    addPost() {
      if (this.posts.length > 0) {
        this.setSuppressFetchConnections(Date.now() + 5000);
      }
      this.posts.push({
        editing: true,
        type: "new",
        church_id: this.$route.params.id,
        title: "",
        connection_id: undefined,
      });
    },
    removePost(index) {
      if (this.posts[index].type === "new") {
        this.posts.splice(index, 1);
      } else {
        this.$set(this.posts[index], "type", "delete");
      }
    },
    modifyPost(index) {
      if (!this.settingConnections) {
        if (this.posts[index].type !== "new") {
          this.$set(this.posts[index], "type", "edit");
        }
      }
    },
    editPost(index) {
      this.posts[index].editing = true;
      this.$set(this.originalPosts, index, { ...this.posts[index] });
    },
    cancelEditPost(index) {
      this.$set(this.posts, index, { ...this.originalPosts[index] });
      this.posts[index].editing = false;
      this.originalPosts.splice(index, 1);
    },
    submitPosts() {
      this.isSubmitting = true;
      var allPromises = [];
      var axiosPromise;
      this.posts.forEach((post) => {
        switch (post.type) {
          case "new":
            if (post.connection_id === undefined && post.title === "") {
              break;
            }
            axiosPromise = this.axios
              .post("https://ems.awana.org.hk/api/api.php/records/post", post, {
                withCredentials: true,
              })
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
            allPromises.push(axiosPromise);
            break;
          case "delete":
            axiosPromise = this.axios
              .delete(
                "https://ems.awana.org.hk/api/api.php/records/post/" + post.id,
                {
                  withCredentials: true,
                }
              )
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
            allPromises.push(axiosPromise);
            break;
          case "edit":
            axiosPromise = this.axios
              .put(
                "https://ems.awana.org.hk/api/api.php/records/post/" + post.id,
                post,
                {
                  withCredentials: true,
                }
              )
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
            allPromises.push(axiosPromise);
            break;
        }
      });
      Promise.all(allPromises).then(() => {
        this.$router.push({
          name: "ChurchView",
          params: { id: this.$route.params.id },
        });
      });
      this.isSubmitting = false;
    },
  },
};
</script>
