var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('h1',[_vm._v(_vm._s(_vm.tableNamePluralDisplay))]),(_vm.showAddButton && _vm.user.role === 'admin')?_c('router-link',{attrs:{"to":{ name: _vm.tableViewName + 'New' }}},[_c('b-button',{staticClass:"left-controls",attrs:{"type":"is-primary","outlined":""}},[_vm._v("Add "+_vm._s(_vm.tableNameDisplay))])],1):_vm._e(),_c('div',{staticClass:"box"},[_c('b-table',{ref:"table",attrs:{"data":_vm.displayRecords,"paginated":true,"paginationPosition":"both","hoverable":true,"mobile-cards":true,"default-sort":_vm.defaultSort,"default-sort-direction":_vm.defaultSortDirection,"scrollable":true,"loading":_vm.isLoading,"per-page":_vm.pageSize,"current-page":_vm.currentPage,"detailed":'detail' in _vm.$scopedSlots},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"top-left",fn:function(){return [_c('JsonCSV',{staticStyle:{"display":"inline-block"},attrs:{"data":_vm.visibleData}},[_c('b-button',{staticClass:"left-controls",attrs:{"icon-left":"download"},on:{"click":function($event){return _vm.csvCompute()}}})],1),_c('b-button',{staticClass:"left-controls",attrs:{"icon-left":"search","type":_vm.activeSearch ? 'is-primary is-light' : ''},on:{"click":_vm.toggleSearch}}),_c('b-dropdown',{staticClass:"left-controls",attrs:{"aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{attrs:{"icon-left":"columns","type":_vm.columns.filter(function (item) { return item.visible == false; }).length > 0
                    ? 'is-primary is-light'
                    : ''}})]},proxy:true}])},_vm._l((_vm.columns),function(column,index){return _c('b-dropdown-item',{key:index,attrs:{"custom":"","aria-role":"listitem"}},[_c('div',{staticClass:"control"},[_c('b-checkbox',{model:{value:(column.visible),callback:function ($$v) {_vm.$set(column, "visible", $$v)},expression:"column.visible"}},[_vm._v(" "+_vm._s(column.label)+" ")])],1)])}),1),_c('b-button',{staticClass:"left-controls",attrs:{"icon-left":"sync"},on:{"click":_vm.loadData}}),_c('b-select',{staticStyle:{"display":"inline-block"},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}},[_c('option',{attrs:{"value":"10"}},[_vm._v("10 per page")]),_c('option',{attrs:{"value":"25"}},[_vm._v("25 per page")]),_c('option',{attrs:{"value":"100"}},[_vm._v("100 per page")]),_c('option',{attrs:{"value":"500"}},[_vm._v("500 per page")])]),_c('b-input',{staticStyle:{"max-width":"100px"},attrs:{"type":"number","placeholder":"Page"},model:{value:(_vm.currentPageInput),callback:function ($$v) {_vm.currentPageInput=$$v},expression:"currentPageInput"}}),(_vm.$route.name === 'ConnectionList')?_c('div',[_c('ClubSelect',{on:{"input":_vm.filterClub}})],1):_vm._e(),(_vm.$route.name === 'ConnectionList')?_c('div',[_c('PostSelect',{on:{"input":_vm.filterPost}})],1):_vm._e()]},proxy:true},{key:"detail",fn:function(props){return [_vm._t("detail",null,null,props)]}}],null,true)},_vm._l((_vm.columns),function(column,index){return _c('b-table-column',{key:index,attrs:{"field":column.field,"label":column.label,"width":column.width,"numeric":column.numeric,"visible":column.visible,"sortable":column.sortable,"searchable":_vm.activeSearch && column.searchable != false,"custom-search":column.customSearch,"custom-sort":column.customSort},scopedSlots:_vm._u([(column.field == 'actions')?{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
                name: _vm.tableViewName + 'View',
                params: { id: props.row.id },
              }}},[_c('b-button',{attrs:{"size":"is-small","icon-left":"eye"}})],1),(_vm.user.role === 'admin')?_c('router-link',{attrs:{"to":{
                name: _vm.tableViewName + 'Edit',
                params: { id: props.row.id },
              }}},[_c('b-button',{attrs:{"size":"is-small","icon-left":"pen"}})],1):_vm._e(),(_vm.user.role === 'admin')?_c('b-button',{attrs:{"size":"is-small","icon-left":"trash"},on:{"click":function($event){return _vm.deleteRecord(props.row)}}}):_vm._e()]}}:(column.type == 'church')?{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row[column.field].name_zh)+" "),_c('br'),_vm._v(" "+_vm._s(props.row[column.field].name_en)+" ")]}}:(column.type == 'boolean')?{key:"default",fn:function(props){return [_c('b-icon',{attrs:{"type":props.row[column.field] ? 'is-success' : 'is-danger',"icon":props.row[column.field] ? 'check' : 'times'}}),_vm._v(" "+_vm._s(_vm.capitalize(props.row[column.field]))+" ")]}}:(column.type == 'user')?{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
                name: 'AdminUserView',
                params: { id: props.row[column.field].id },
              }}},[_vm._v(" "+_vm._s(props.row[column.field].username)+" ")])]}}:{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row[column.field])+" ")]}},(column.type == 'church')?{key:"searchable",fn:function(props){return [_c('ChurchSelect',{staticStyle:{"font-weight":"normal"},model:{value:(props.filters[props.column.field]),callback:function ($$v) {_vm.$set(props.filters, props.column.field, $$v)},expression:"props.filters[props.column.field]"}})]}}:null],null,true)})}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }