<template>
  <div>
    <Navbar active="trainings" />
    <CrudList
      tableName="training"
      tableNamePlural="trainings"
      defaultSort="course_code"
      :columns="columns"
      :dataModifier="dataModifier"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudList from "@/components/CrudList.vue";

export default {
  name: "TrainingList",
  components: {
    Navbar,
    CrudList,
  },
  data() {
    return {
      columns: [
        {
          field: "course_code",
          label: "Code",
          type: "input",
          sortable: true,
          visible: true,
        },
        {
          field: "course_title",
          label: "Title",
          type: "input",
          sortable: true,
          visible: true,
        },
        {
          field: "course_date",
          label: "Date",
          type: "input",
          sortable: true,
          visible: true,
          customSort: (a, b, isAsc) => {
            var aDate = new Date(a.course_date);
            var bDate = new Date(b.course_date);
            if (isNaN(aDate)) {
              aDate = new Date("2200-01-01T00:00:00");
            }
            if (isNaN(bDate)) {
              bDate = new Date("2200-01-01T00:00:00");
            }
            if (isAsc) {
              return aDate - bDate;
            } else {
              return bDate - aDate;
            }
          },
        },
        {
          field: "course_level",
          label: "Level",
          type: "input",
          sortable: true,
          visible: true,
        },
        {
          field: "actions",
          label: "Actions",
          sortable: false,
          visible: true,
          searchable: false,
          width: "140px",
        },
      ],
    };
  },
  methods: {
    dataModifier(records) {
      return records;
    },
  },
};
</script>
