<template>
  <b-taginput
    ref="autocomplete"
    v-model="selectedTitles"
    :data="filteredTitles"
    placeholder="Select a title"
    :open-on-focus="true"
    :loading="fetchingTitles"
    autocomplete
    @input="$emit('input', selectedTitles)"
    @typing="getFilteredTitles"
  />
</template>

<style scoped lang="less"></style>

<script>
export default {
  name: "PostSelect",
  data() {
    return {
      titles: [],
      selectedTitles: [],
      titleSearch: "",
      fetchingTitles: false,
      filteredTitles: [],
    };
  },
  mounted() {
    this.fetchingTitles = true;
    this.axios
      .get("https://ems.awana.org.hk/functions/post-titles.php", {
        withCredentials: true,
      })
      .then((response) => {
        this.titles = response.data;
        this.filteredTitles = response.data;
        this.fetchingTitles = false;
      });
  },
  methods: {
    getFilteredTitles(search) {
      if (search === "") {
        return this.titles;
      } else {
        return this.titles.filter((record) => {
          return record.toString().indexOf(search.toString()) >= 0;
        });
      }
    },
  },
};
</script>
