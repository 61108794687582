<template>
  <div>
    <Navbar active="clubs" />
    <CrudNew
      tableName="club"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudNew from "@/components/CrudNew.vue";

export default {
  name: "ClubNew",
  components: {
    Navbar,
    CrudNew,
  },
  data() {
    return {
      fields: [
        { field: "reg_num", label: "Registration #" },
        { field: "reg_date", label: "Registration Date" },
        { field: "church_id", label: "Church", type: "church" },
        { field: "name_zh", label: "Name (Chinese)" },
        { field: "name_en", label: "Name (English)" },
        { field: "phone", label: "Phone" },
        { field: "email", label: "Email" },
        { field: "existing", label: "Existing", type: "switch" },
        { field: "notes", label: "Notes" },
      ],
    };
  },
  methods: {
    titleRenderer(fieldValues) {
      return "Add Club: " + fieldValues.reg_num;
    },
    dataModifier(fieldValues) {
      if (fieldValues.name_zh === "" && fieldValues.name_en === "") {
        return "A club must at least have a name in Chinese or English";
      }
      fieldValues.existing = fieldValues.existing ? 1 : 0;
      return fieldValues;
    },
  },
};
</script>
