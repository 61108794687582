var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navbar',{attrs:{"active":"connections"}}),_c('CrudView',{attrs:{"tableName":"connection","fields":_vm.fields,"titleRenderer":_vm.titleRenderer,"dataModifier":_vm.dataModifier}},[[_c('div',{staticClass:"box",staticStyle:{"position":"relative"}},[(_vm.fetchingPosts || _vm.fetchingEnrollments)?_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}):_vm._e(),_c('div',{attrs:{"clss":"data-container"}},[_c('span',{staticClass:"data-label"},[_vm._v("Posts")]),_c('div',{staticClass:"data-content"},_vm._l((_vm.posts),function(post){return _c('div',{key:post.id},[(post.club_id != undefined)?[_vm._v(" "+_vm._s(post.title)+" @ "),_c('router-link',{key:post.id,attrs:{"to":{
                    name: 'ClubView',
                    params: { id: post.club_id.id },
                  }}},[_vm._v(" "+_vm._s(post.club_id.reg_num)+" ")])]:(post.church_id != undefined)?[_vm._v(" "+_vm._s(post.title)+" @ "),_c('router-link',{key:post.id,attrs:{"to":{
                    name: 'ChurchView',
                    params: { id: post.church_id.id },
                  }}},[_vm._v(" "+_vm._s(post.church_id.name_zh)+" "+_vm._s(post.church_id.name_en)+" ")])]:_vm._e()],2)}),0)])],1),_c('div',{staticClass:"box"},[_c('div',{attrs:{"clss":"data-container"}},[_c('span',{staticClass:"data-label"},[_vm._v("Enrollments")]),_c('div',{staticClass:"data-content"},[_c('b-steps',{attrs:{"has-navigation":false,"mobile-mode":"compact"},model:{value:(_vm.trainingProgress),callback:function ($$v) {_vm.trainingProgress=$$v},expression:"trainingProgress"}},[_c('b-step-item',{attrs:{"step":"0","value":"0","clickable":false}}),_c('b-step-item',{attrs:{"step":"1","value":"1","clickable":false}}),_c('b-step-item',{attrs:{"step":"2","value":"2","clickable":false}}),_c('b-step-item',{attrs:{"step":"3","value":"3","clickable":false}})],1),_vm._l((_vm.enrollments),function(enrollment){return _c('div',{key:enrollment.id},[_vm._v(" "+_vm._s(enrollment.result)+" @ "),_c('router-link',{attrs:{"to":{
                  name: 'TrainingView',
                  params: { id: enrollment.training_id.id },
                }}},[_vm._v(" "+_vm._s(enrollment.training_id.course_code)+" "+_vm._s(enrollment.training_id.course_title)+" ("+_vm._s(enrollment.training_id.course_date)+") ")]),(enrollment.training_id.course_level)?[_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v(_vm._s(enrollment.training_id.course_level))])]:_vm._e(),(enrollment.notes != '')?[_vm._v(" ("+_vm._s(enrollment.notes)+") ")]:_vm._e()],2)})],2)])])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }