<template>
  <div class="navbar-row">
    <div class="container">
      <b-navbar>
        <template #brand>
          <b-navbar-item tag="router-link" :to="{ name: 'Dashboard' }">
            <b>Awana EMS</b>
          </b-navbar-item>
        </template>
        <template #start v-if="'id' in user">
          <b-navbar-item
            tag="router-link"
            :active="active == 'dashboard'"
            :to="{ name: 'Dashboard' }"
          >
            Dashboard
          </b-navbar-item>
          <b-navbar-dropdown label="Data">
            <b-navbar-item
              tag="router-link"
              :active="active == 'connections'"
              :to="{ name: 'ConnectionList' }"
            >
              Connections
            </b-navbar-item>
            <b-navbar-item
              tag="router-link"
              :active="active == 'clubs'"
              :to="{ name: 'ClubList' }"
            >
              Clubs
            </b-navbar-item>
            <b-navbar-item
              tag="router-link"
              :active="active == 'churches'"
              :to="{ name: 'ChurchList' }"
            >
              Churches
            </b-navbar-item>
            <b-navbar-item
              tag="router-link"
              :active="active == 'trainings'"
              :to="{ name: 'TrainingList' }"
            >
              Trainings
            </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-dropdown label="Utilities">
            <b-navbar-item
              tag="router-link"
              :active="active == 'utilities_mailing_list'"
              :to="{ name: 'UtilitiesMailingList' }"
            >
              Mailing List
            </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-dropdown label="Admin" v-if="user.role === 'admin'">
            <b-navbar-item
              tag="router-link"
              :active="active == 'admin_users'"
              :to="{ name: 'AdminUserList' }"
            >
              Users
            </b-navbar-item>
            <b-navbar-item
              tag="router-link"
              :active="active == 'admin_logs'"
              :to="{ name: 'AdminLogList' }"
            >
              Logs
            </b-navbar-item>
            <!-- <b-navbar-item
              tag="router-link"
              :active="active == 'admin_settings'"
              :to="{ name: 'AdminSettings' }"
            >
              Settings
            </b-navbar-item> -->
          </b-navbar-dropdown>
        </template>

        <template #end v-if="'id' in user">
          <b-navbar-item tag="div">
            <div class="buttons">
              <a class="button is-light" @click="logout">Log out</a>
            </div>
          </b-navbar-item>
        </template>
      </b-navbar>
    </div>
  </div>
</template>

<style lang="less" scoped>
.navbar-row {
  background-color: #ffffff;
}

a.navbar-item.is-active {
  border-bottom: 3px solid #7957d5;
  padding-top: calc(0.5rem + 3px);
}

/deep/ .navbar-link:not(.is-arrowless)::after {
  border-color: #4a4a4a;
  margin-top: -0.55em;
}
</style>

<script>
import { mapState, mapMutations } from "vuex";
import logsMixin from "@/mixins/logsMixin.js";

export default {
  name: "Navbar",
  mixins: [logsMixin],
  props: {
    active: String,
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["setUser"]),
    logout() {
      this.log("auth", {
        type: "logout",
      });
      this.axios.post("https://ems.awana.org.hk/api/api.php/logout");
      this.setUser({});
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
