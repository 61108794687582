<template>
  <div class="section">
    <div class="container">
      <h1 v-html="titleRenderer(record)"></h1>
      <router-link :to="{ name: tableNameDisplay + 'List' }" class="pr-2">
        <b-button type="is-primary" class="left-controls" outlined
          >Back to {{ tableNameDisplay }} List</b-button
        >
      </router-link>
      <router-link
        v-if="user.role === 'admin' && showEditButton"
        :to="{
          name: tableNameDisplay + 'Edit',
          params: { id: $route.params.id },
        }"
      >
        <b-button type="is-primary" class="left-controls" outlined
          >Edit</b-button
        >
      </router-link>
      <div :class="{ columns: !isHorizontal }">
        <div :class="{ column: !isHorizontal }">
          <div
            :class="{ box: true, horizontal: isHorizontal }"
            style="position: relative"
          >
            <b-loading
              :is-full-page="false"
              v-model="isLoading"
              v-if="isLoading"
            ></b-loading>
            <template v-else>
              <div
                class="data-container"
                v-for="field in fields"
                :key="field.field"
              >
                <span class="data-label">{{ field.label }}</span>
                <span class="data-content">
                  <template v-if="typeof record[field.field] == 'boolean'">
                    <b-icon
                      :type="record[field.field] ? 'is-success' : 'is-danger'"
                      :icon="record[field.field] ? 'check' : 'times'"
                    >
                    </b-icon>
                    {{ capitalize(record[field.field]) }}
                  </template>
                  <template v-else-if="field.type == 'church'">
                    <router-link
                      :to="{
                        name: 'ChurchView',
                        params: { id: record[field.field].id },
                      }"
                    >
                      {{ record[field.field].name_zh }}
                      <br />
                      {{ record[field.field].name_en }}
                    </router-link>
                  </template>
                  <template v-else>
                    {{ record[field.field] }}
                  </template>
                </span>
              </div>
            </template>
          </div>
        </div>
        <div :class="{ column: !isHorizontal }"><slot></slot></div>
      </div>
      <slot name="below-all"></slot>
    </div>
  </div>
</template>

<style scoped lang="less">
.box {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: none;
  min-height: 60px;
}

.data-label {
  display: block;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 4px;
}

.data-content {
  display: block;
  font-size: 16px;
}

.data-container:not(:last-child) {
  margin-bottom: 20px;
}

.horizontal {
  display: flex;
  justify-content: space-between;
  padding-right: 100px;
  .data-container {
    margin-bottom: 0;
  }
}
</style>

<script>
import stringsMixin from "@/mixins/stringsMixin";
import { mapState } from "vuex";

export default {
  name: "CrudView",
  mixins: [stringsMixin],
  props: {
    tableName: String,
    fields: Array,
    titleRenderer: Function,
    showEditButton: {
      type: Boolean,
      default: true,
    },
    dataModifier: {
      type: Function,
      default: (data) => {
        return data;
      },
    },
    queryParams: {
      type: String,
      default: "",
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      record: {},
      isLoading: true,
    };
  },
  computed: {
    ...mapState(["user"]),
    tableNameDisplay: function () {
      return this.capitalizeAll(this.tableName);
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.axios
        .get(
          "https://ems.awana.org.hk/api/api.php/records/" +
            this.tableName +
            "/" +
            this.$route.params.id +
            this.queryParams,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.record = this.dataModifier(response.data);
          this.$emit("record-ready", this.record);
        });
    },
  },
};
</script>
