<template>
  <div>
    <Navbar active="admin_users" />
    <CrudList
      tableName="admin_user"
      tableNamePlural="admin_users"
      defaultSort="id"
      :columns="columns"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudList from "@/components/CrudList.vue";
import stringsMixin from "@/mixins/stringsMixin";

export default {
  name: "AdminUserList",
  mixins: [stringsMixin],
  components: {
    Navbar,
    CrudList,
  },
  data() {
    return {
      columns: [
        {
          field: "id",
          label: "ID",
          sortable: true,
          numeric: true,
          visible: false,
          width: "60px",
        },
        {
          field: "username",
          label: "Username",
          sortable: true,
          visible: true,
        },
        {
          field: "role",
          label: "Role",
          sortable: true,
          visible: true,
        },
        {
          field: "actions",
          label: "Actions",
          sortable: false,
          visible: true,
          searchable: false,
          width: "140px",
        },
      ],
    };
  },
};
</script>
