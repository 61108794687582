<template>
  <div>
    <Navbar active="churches" />
    <CrudNew
      tableName="church"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudNew from "@/components/CrudNew.vue";

export default {
  label: "ChurchNew",
  components: {
    Navbar,
    CrudNew,
  },
  data() {
    return {
      fields: [
        {
          field: "name_zh",
          label: "Name (Chinese)",
        },
        {
          field: "name_en",
          label: "Name (English)",
        },
        {
          field: "addr",
          label: "Address",
        },
        {
          field: "district",
          label: "District",
          type: "district",
        },
        {
          field: "phone",
          label: "Phone",
        },
        {
          field: "email",
          label: "Email",
        },
      ],
    };
  },
  methods: {
    titleRenderer(fieldValues) {
      var titleString =
        "New Church:<br />" +
        fieldValues.name_zh +
        "<br />" +
        fieldValues.name_en;
      return titleString;
    },
    dataModifier(fieldValues) {
      if (fieldValues.name_zh === "" && fieldValues.name_en === "") {
        return "A church must at least have a name in Chinese or English";
      }
      return fieldValues;
    },
  },
};
</script>
