<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>

<style lang="less">
html {
  height: 100%;
}

body {
  background-color: #f6f6f6;
  min-height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;

  h1 {
    font-size: 26px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 16px;
  }
}
</style>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    if ("id" in this.user) {
      this.$gtag.config({ user_id: this.user.id });
    }
  },
};
</script>
