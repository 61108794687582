<template>
  <div>
    <Navbar active="trainings" />
    <CrudEdit
      tableName="training"
      queryParams="?join=enrollment,connection"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
    >
      <template>
        <div class="box" ref="enrollmentBox" style="position: relative">
          <div class="label">Enrollments</div>
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            v-if="
              fetchingConnections ||
              fetchingEnrollments ||
              connections.length === 0
            "
          ></b-loading>
          <form v-else @submit.prevent="submitEnrollments">
            <div
              v-for="(enrollment, index) in enrollments"
              :key="index"
              class="rows"
            >
              <div
                v-if="enrollment.type !== 'delete'"
                class="columns is-variable is-1"
              >
                <template v-if="enrollment.editing">
                  <div class="column">
                    <ConnectionSelect
                      @input="modifyEnrollment(index)"
                      v-model="enrollment.connection_id"
                      @selectByIdStart="settingConnections = true"
                      @selectByIdEnd="settingConnections = false"
                      :skipGetConnection="true"
                    />
                  </div>
                  <div class="column">
                    <b-input
                      @input="modifyEnrollment(index)"
                      placeholder="Result"
                      v-model="enrollment.result"
                    ></b-input>
                  </div>
                  <div class="column">
                    <b-input
                      @input="modifyEnrollment(index)"
                      placeholder="Notes"
                      v-model="enrollment.notes"
                    ></b-input>
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      @click="enrollments[index].editing = false"
                      type="is-success is-light"
                      icon-right="check"
                    />
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      @click="cancelEditEnrollment(index)"
                      type="is-danger is-light"
                      icon-right="times"
                    />
                  </div>
                </template>
                <template v-else>
                  <div class="column">
                    {{ connections[enrollment.connection_id].name_zh }}
                    {{ connections[enrollment.connection_id].name_en }}
                  </div>
                  <div class="column">
                    {{ enrollment.result }}
                  </div>
                  <div class="column">
                    {{ enrollment.notes }}
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      @click="editEnrollment(index)"
                      type="is-primary is-light"
                      icon-right="pen"
                    />
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      @click="removeEnrollment(index)"
                      type="is-danger is-light"
                      icon-right="trash"
                    />
                  </div>
                </template>
              </div>
            </div>
            <div class="buttons">
              <b-button @click="addEnrollment" expanded
                >Add Enrollment</b-button
              >
              <b-button
                type="is-primary"
                native-type="submit"
                :loading="isSubmitting"
                >Update Enrollments</b-button
              >
            </div>
          </form>
        </div>
      </template>
    </CrudEdit>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudEdit from "@/components/CrudEdit.vue";
import ConnectionSelect from "@/components/ConnectionSelect.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "TrainingEdit",
  components: {
    Navbar,
    CrudEdit,
    ConnectionSelect,
  },
  data() {
    return {
      fetchingEnrollments: true,
      isLoading: true,
      originalEnrollments: [],
      enrollments: [],
      isSubmitting: false,
      settingConnections: false,
      fields: [
        { field: "id", label: "ID" },
        { field: "course_code", label: "Code", type: "input" },
        { field: "course_title", label: "Title", type: "input" },
        { field: "course_date", label: "Date", type: "input" },
        { field: "course_level", label: "Level", type: "input" },
      ],
    };
  },
  computed: {
    ...mapState([
      "connections",
      "fetchingConnections",
      "suppressFetchConnections",
    ]),
  },
  mounted() {
    this.getConnections();
    this.fetchingEnrollments = true;
    this.axios
      .get(
        "https://ems.awana.org.hk/api/api.php/records/enrollment?filter=training_id,eq," +
          this.$route.params.id,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        const localEnrollments = response.data.records;
        localEnrollments.forEach((enrollment) => {
          enrollment.type = "existing";
          enrollment.editing = false;
        });
        this.enrollments = localEnrollments;
        this.fetchingEnrollments = false;
      });
  },
  methods: {
    ...mapActions(["getConnections"]),
    ...mapMutations(["setSuppressFetchConnections"]),
    titleRenderer(fieldValues) {
      var titleString =
        "Edit Training: " +
        [fieldValues.course_code, fieldValues.course_title].join(" ");
      return titleString;
    },
    dataModifier(fieldValues) {
      if (fieldValues.course_code === "") {
        return "A training must at least have a course code";
      }
      return fieldValues;
    },
    addEnrollment() {
      if (this.enrollments.length > 0) {
        this.setSuppressFetchConnections(Date.now() + 5000);
      }
      this.enrollments.push({
        editing: true,
        type: "new",
        connection_id: undefined,
        notes: "",
        result: "",
        training_id: this.$route.params.id,
      });
    },
    editEnrollment(index) {
      this.enrollments[index].editing = true;
      this.$set(this.originalEnrollments, index, {
        ...this.enrollments[index],
      });
    },
    cancelEditEnrollment(index) {
      this.$set(this.enrollments, index, {
        ...this.originalEnrollments[index],
      });
      this.enrollments[index].editing = false;
      this.originalEnrollments.splice(index, 1);
    },
    removeEnrollment(index) {
      if (this.enrollments[index].type === "new") {
        this.enrollments.splice(index, 1);
      } else {
        this.$set(this.enrollments[index], "type", "delete");
      }
    },
    modifyEnrollment(index) {
      if (!this.settingConnections) {
        if (this.enrollments[index].type !== "new") {
          this.$set(this.enrollments[index], "type", "edit");
        }
      }
    },
    submitEnrollments() {
      this.isSubmitting = true;
      var allPromises = [];
      var axiosPromise;
      this.enrollments.forEach((enrollment) => {
        switch (enrollment.type) {
          case "new":
            if (enrollment.editing) {
              return;
            }
            axiosPromise = this.axios
              .post(
                "https://ems.awana.org.hk/api/api.php/records/enrollment",
                enrollment,
                {
                  withCredentials: true,
                }
              )
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });
            allPromises.push(axiosPromise);
            break;
          case "delete":
            axiosPromise = this.axios
              .delete(
                "https://ems.awana.org.hk/api/api.php/records/enrollment/" +
                  enrollment.id,
                {
                  withCredentials: true,
                }
              )
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });
            allPromises.push(axiosPromise);
            break;
          case "edit":
            if (enrollment.editing) {
              return;
            }
            axiosPromise = this.axios
              .put(
                "https://ems.awana.org.hk/api/api.php/records/enrollment/" +
                  enrollment.id,
                enrollment,
                {
                  withCredentials: true,
                }
              )
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });
            allPromises.push(axiosPromise);
            break;
        }
      });
      Promise.all(allPromises).then(() => {
        this.$router.push({
          name: "TrainingView",
          params: { id: this.$route.params.id },
        });
      });
      this.isSubmitting = false;
    },
  },
};
</script>
