<template>
  <div>
    <Navbar active="utilities_mailing_list" />
    <div class="section">
      <div class="container">
        <h1>Mailing Lists</h1>
        <div class="box">
          <!-- <span class="box-title">By Title</span> -->
          <div class="is-flex">
            <div class="is-flex-grow-1 pr-2">
              <b-taginput
                ref="autocomplete"
                v-model="selectedTitles"
                :data="filteredTitles"
                placeholder="Select a title"
                :open-on-focus="true"
                :loading="fetchingTitles"
                autocomplete
                @input="criteriaChanged"
                @typing="getFilteredTitles"
              />
            </div>
            <b-button type="is-danger" outlined @click="clearSelection()"
              >Clear</b-button
            >
          </div>
          <div class="buttons" style="margin-top: 10px; margin-bottom: 0">
            <b-button type="is-primary" outlined @click="quickSelect('pastor')"
              >Pastor</b-button
            >
            <b-button
              type="is-primary"
              outlined
              @click="quickSelect('commander')"
              >Commander</b-button
            >
            <b-button
              type="is-primary"
              outlined
              @click="quickSelect('secretary')"
              >Secretary</b-button
            >
            <b-button type="is-primary" outlined @click="quickSelect('使命隊')"
              >使命隊</b-button
            >
            <b-button type="is-primary" outlined @click="quickSelect('董事')"
              >董事</b-button
            >
            <b-button type="is-primary" outlined @click="quickSelect('awanax')"
              >AwanaX</b-button
            >
          </div>
          <div class="buttons">
            <b-button
              type="is-primary"
              outlined
              @click="quickSelect('puggles')"
            >
              Puggles
            </b-button>
            <b-button type="is-primary" outlined @click="quickSelect('cubbies')"
              >Cubbies</b-button
            >
            <b-button type="is-primary" outlined @click="quickSelect('sparks')"
              >Sparks</b-button
            >
            <b-button type="is-primary" outlined @click="quickSelect('tnt')"
              >T&T</b-button
            >
            <b-button type="is-primary" outlined @click="quickSelect('trek')"
              >Trek</b-button
            >
            <b-button type="is-primary" outlined @click="quickSelect('journey')"
              >Journey</b-button
            >
          </div>
        </div>
        <div class="box">
          <b-table
            ref="table"
            :data="connections"
            :paginated="true"
            paginationPosition="both"
            :hoverable="true"
            :mobile-cards="true"
            :scrollable="true"
          >
            <template #top-left>
              <b-button
                type="is-primary"
                :disabled="connections.length === 0"
                @click="getEmail"
                >Get Email</b-button
              >
            </template>
            <b-table-column field="name" label="Connection" v-slot="props">
              <router-link
                :to="{
                  name: 'ConnectionView',
                  params: { id: props.row.connection_id.id },
                }"
              >
                {{ props.row.connection_id.name_zh }}
                {{ props.row.connection_id.name_en }}
              </router-link>
            </b-table-column>
            <b-table-column field="post" label="Post" v-slot="props">
              {{ props.row.title }}
            </b-table-column>
            <b-table-column field="email" label="Email" v-slot="props">
              {{ props.row.connection_id.email }}
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
    <b-modal
      v-model="isEmailModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      aria-modal
    >
      <template #default>
        <!-- <modal-form v-bind="formProps" @close="props.close"></modal-form> -->
        <div class="modal-card" style="border-radius: 4px">
          <div class="modal-card-body">
            <b>Filtered connections:</b> {{ connections.length }}<br />
            <b>Unique valid emails:</b> {{ emailList.length }}<br />
            {{ emailList.join(", ") }}<br />
            <b>Invalid emails:</b> {{ illegalEmailConnections.length }}<br />
            <template v-for="connection in illegalEmailConnections">
              <span :key="connection.connection_id.id" style="display: block">
                <router-link
                  :to="{
                    name: 'ConnectionView',
                    params: { id: connection.connection_id.id },
                  }"
                  >{{ connection.connection_id.name_zh }}
                  {{ connection.connection_id.name_en }}</router-link
                >: {{ connection.connection_id.email }}
              </span>
            </template>
          </div>
        </div>
        <!-- <div>x</div> -->
      </template>
    </b-modal>
  </div>
</template>

<style scoped>
.box {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: none;
}

.box-title {
  font-size: 22px;
  display: block;
  margin-bottom: 20px;
}
</style>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "UtilitiesMailingList",
  components: {
    Navbar,
  },
  data() {
    return {
      titles: [],
      selectedTitles: [],
      titleSearch: "",
      fetchingTitles: false,
      filteredTitles: [],
      connections: [],
      isEmailModalActive: false,
      emailModalContent: "",
      emailList: [],
      illegalEmailConnections: [],
    };
  },
  mounted() {
    this.fetchingTitles = true;
    this.axios
      .get("https://ems.awana.org.hk/functions/post-titles.php", {
        withCredentials: true,
      })
      .then((response) => {
        this.titles = response.data;
        this.filteredTitles = response.data;
        this.fetchingTitles = false;
      });
  },
  methods: {
    getFilteredTitles(search) {
      if (search === "") {
        return this.titles;
      } else {
        return this.titles.filter((record) => {
          return record.toString().indexOf(search.toString()) >= 0;
        });
      }
    },
    criteriaChanged() {
      if (this.selectedTitles.length === 0) {
        this.connections = [];
        return;
      }
      let queryString = "";
      for (let i = 0; i < this.selectedTitles.length; i++) {
        if (i !== 0) {
          queryString += "&";
        }
        queryString += "filter" + i + "=title,eq," + this.selectedTitles[i];
      }
      this.axios
        .get(
          "https://ems.awana.org.hk/api/records/post?" +
            queryString +
            "&join=connection",
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          this.connections = response.data.records;
        })
        .catch((error) => {
          if (!error.response) {
            this.$buefy.snackbar.open({
              message: "Too many titles selected",
              type: "is-danger",
            });
          }
        });
    },
    clearSelection() {
      this.selectedTitles = [];
      this.criteriaChanged();
    },
    quickSelect(type) {
      let filters = [];
      switch (type) {
        case "commander":
          filters = ["總指揮"];
          break;
        case "secretary":
          filters = ["營秘書"];
          break;
        case "pastor":
          filters = ["營牧"];
          break;
        case "使命隊":
          filters = ["使命隊"];
          break;
        case "董事":
          filters = ["董事"];
          break;
        case "awanax":
          filters = ["awanax"];
          break;
        case "puggles":
          filters = ["小寶"];
          break;
        case "cubbies":
          filters = ["小熊"];
          break;
        case "sparks":
          filters = ["火花"];
          break;
        case "tnt":
          filters = ["見習", "真理", "奇兵"];
          break;
        case "trek":
          filters = ["旅途"];
          break;
        case "journey":
          filters = ["旅程"];
          break;
      }
      this.selectedTitles.push(
        ...this.titles.filter((record) => {
          for (let i = 0; i < filters.length; i++) {
            if (
              record
                .toString()
                .toLowerCase()
                .indexOf(filters[i].toLowerCase()) >= 0 &&
              !this.selectedTitles.includes(record)
            ) {
              return true;
            }
          }
          return false;
        })
      );
      this.criteriaChanged();
    },
    getEmail() {
      this.emailList = [
        ...new Set(
          this.connections
            .map((connection) => {
              return connection.connection_id.email;
            })
            .filter((email) => {
              return email !== "";
            })
        ),
      ];
      this.emailList = [];
      this.illegalEmailConnections = [];
      this.connections.forEach((connection) => {
        var email = connection.connection_id.email;
        if (
          email
            .toLowerCase()
            .trim()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          this.emailList.push(email.trim());
        } else {
          this.illegalEmailConnections.push(connection);
        }
      });
      this.isEmailModalActive = true;
    },
  },
};
</script>
