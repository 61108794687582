<template>
  <b-select v-model="inputValue" placeholder="Select a district" expanded>
    <optgroup label="Hong Kong Island">
      <option value="central-and-western">Central and Western</option>
      <option value="eastern">Eastern</option>
      <option value="southern">Southern</option>
      <option value="wan-chai">Wan Chai</option>
    </optgroup>
    <optgroup label="Kowloon">
      <option value="sham-shui-po">Sham Shui Po</option>
      <option value="kowloon-city">Kowloon City</option>
      <option value="kwun-tong">Kwun Tong</option>
      <option value="wong-tai-sin">Wong Tai Sin</option>
      <option value="yau-tsim-mong">Yau Tsim Mong</option>
    </optgroup>
    <optgroup label="New Territories">
      <option value="islands">Islands</option>
      <option value="kwai-tsing">Kwai Tsing</option>
      <option value="north">North</option>
      <option value="sai-kung">Sai Kung</option>
      <option value="sha-tin">Sha Tin</option>
      <option value="tai-po">Tai Po</option>
      <option value="tsuen-wan">Tsuen Wan</option>
      <option value="tuen-mun">Tuen Mun</option>
      <option value="yuen-long">Yuen Long</option>
    </optgroup>
    <optgroup label="Others">
      <option value="macau">Macau</option>
      <option value="malaysia">Malaysia</option>
      <option value="tai-wan">Tai Wan</option>
    </optgroup>
    <optgroup label="More Others">
      <option
        v-for="country in countries"
        :key="country[0]"
        :value="country[0]"
      >
        {{ country[1] }}
      </option>
    </optgroup>
  </b-select>
</template>

<script>
var countries = require("i18n-iso-countries");

export default {
  name: "DistrictSelect",
  props: ["value"],
  data() {
    return {
      countries: {},
    };
  },
  mounted() {
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    this.countries = Object.entries(
      countries.getNames("en", { select: "alias" })
    ).sort(function (a, b) {
      return a[1] - b[1];
    });
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
