<template>
  <b-autocomplete
    ref="autocomplete"
    v-model="inputValue"
    placeholder="Select a club"
    :open-on-focus="true"
    :data="filteredRecords"
    :custom-formatter="customFormatter"
    :loading="fetchingClubs"
    @select="onSelect"
  >
    <template slot-scope="props">
      {{ props.option.reg_num }}
      <br />
      {{ props.option.name_zh }}
      <br />
      {{ props.option.name_en }}
    </template>
  </b-autocomplete>
</template>

<style scoped lang="less"></style>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ClubSelect",
  props: ["value"],
  data() {
    return {
      records: [],
      isLoading: true,
      inputValue: this.value,
    };
  },
  computed: {
    ...mapState(["clubs", "fetchingClubs"]),
    filteredRecords: function () {
      if (
        this.inputValue === undefined ||
        this.inputValue === null ||
        typeof this.value === "number"
      ) {
        return this.clubs;
      } else {
        return this.clubs.filter((record) => {
          return (
            record.reg_num.toString().indexOf(this.inputValue.toString()) >=
              0 ||
            record.name_zh.toString().indexOf(this.inputValue.toString()) >=
              0 ||
            record.name_en
              .toString()
              .toLowerCase()
              .indexOf(this.inputValue.toString().toLowerCase()) >= 0
          );
        });
      }
    },
  },
  watch: {
    value: function () {
      this.selectById();
    },
    clubs: function () {
      this.selectById();
    },
  },
  mounted() {
    this.getClubs();
  },
  methods: {
    ...mapActions(["getClubs"]),
    selectById() {
      this.$emit("selectByIdStart");
      for (var i = 0; i < this.clubs.length; i++) {
        if (this.clubs[i].id === this.value) {
          this.$refs.autocomplete.setSelected(this.clubs[i]);
          break;
        }
      }
      this.$emit("selectByIdEnd");
    },
    customFormatter(record) {
      return [record.reg_num, record.name_zh, record.name_en].join(" ");
    },
    onSelect(option) {
      if (option === null) {
        this.$emit("input", undefined);
      } else {
        this.$emit("input", option.id);
      }
    },
  },
};
</script>
