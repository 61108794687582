<template>
  <div class="section">
    <div class="container">
      <h1 v-html="titleRenderer(fieldValues)"></h1>
      <div class="columns">
        <div class="column">
          <div class="box">
            <form @submit.prevent="submitForm">
              <b-field
                v-for="field in fields"
                :label="field.label"
                :key="field.field"
              >
                <DistrictSelect
                  v-if="field.type == 'district'"
                  v-model="fieldValues[field.field]"
                />
                <ChurchSelect
                  v-else-if="field.type == 'church'"
                  v-model="fieldValues[field.field]"
                />
                <b-switch
                  v-else-if="field.type == 'switch'"
                  v-model="fieldValues[field.field]"
                >
                  {{ capitalize(fieldValues[field.field]) }}
                </b-switch>
                <b-input v-else v-model="fieldValues[field.field]"></b-input>
              </b-field>
              <router-link
                :to="{
                  name: capitalizeAll(tableName).replace(' ', '') + 'List',
                }"
                style="margin-right: 5px"
              >
                <b-button>Cancel and Return</b-button>
              </router-link>
              <b-button
                type="is-primary"
                native-type="submit"
                :loading="isSubmitting"
                >Add New {{ capitalize(tableName) }}</b-button
              >
            </form>
          </div>
        </div>
        <div class="column"><slot></slot></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.box {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: none;

  .rows:not(:last-of-type) .columns {
    margin-bottom: 0;
  }
}

/deep/ .label {
  display: block;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 4px;
}

/deep/ .field:not(:last-child) {
  margin-bottom: 20px;
}
</style>

<script>
import DistrictSelect from "@/components/DistrictSelect.vue";
import ChurchSelect from "@/components/ChurchSelect.vue";
import stringsMixin from "@/mixins/stringsMixin";

export default {
  name: "CrudNew",
  mixins: [stringsMixin],
  components: {
    DistrictSelect,
    ChurchSelect,
  },
  props: {
    tableName: String,
    fields: Array,
    titleRenderer: Function,
    dataModifier: {
      type: Function,
      default: (data) => {
        return data;
      },
    },
    readyForReload: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      entryId: undefined,
      isSubmitting: false,
      fieldValues: {},
    };
  },
  watch: {
    readyForReload: function (val) {
      if (val === true) {
        this.goToView();
      }
    },
  },
  mounted() {
    this.fields.forEach((field) => {
      if (field.type === "switch") {
        this.$set(this.fieldValues, field.field, false);
      } else {
        this.$set(this.fieldValues, field.field, "");
      }
    });
  },
  methods: {
    submitForm() {
      var fieldValuesSubmit = this.dataModifier(this.fieldValues);
      if (typeof fieldValuesSubmit === "string") {
        this.$buefy.snackbar.open({
          message: fieldValuesSubmit,
          type: "is-danger",
        });
        return;
      }

      this.isSubmitting = true;
      this.axios
        .post(
          "https://ems.awana.org.hk/api/api.php/records/" + this.tableName,
          fieldValuesSubmit,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          this.entryId = response.data;
          if (this.readyForReload) {
            this.goToView();
          } else {
            this.$emit("beforeReload", this.entryId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToView() {
      this.$router.push({
        name: this.capitalizeAll(this.tableName).replace(" ", "") + "View",
        params: { id: this.entryId },
      });
    },
  },
};
</script>
