<template>
  <div class="section">
    <div class="container">
      <h1>{{ tableNamePluralDisplay }}</h1>
      <router-link
        :to="{ name: tableViewName + 'New' }"
        v-if="showAddButton && user.role === 'admin'"
      >
        <b-button type="is-primary" class="left-controls" outlined
          >Add {{ tableNameDisplay }}</b-button
        >
      </router-link>
      <div class="box">
        <b-table
          ref="table"
          :data="displayRecords"
          :paginated="true"
          paginationPosition="both"
          :hoverable="true"
          :mobile-cards="true"
          :default-sort="defaultSort"
          :default-sort-direction="defaultSortDirection"
          :scrollable="true"
          :loading="isLoading"
          :per-page="pageSize"
          :current-page.sync="currentPage"
          :detailed="'detail' in $scopedSlots"
        >
          <template #top-left>
            <JsonCSV :data="visibleData" style="display: inline-block">
              <b-button
                icon-left="download"
                @click="csvCompute()"
                class="left-controls"
              />
            </JsonCSV>
            <b-button
              icon-left="search"
              @click="toggleSearch"
              class="left-controls"
              :type="activeSearch ? 'is-primary is-light' : ''"
            />
            <b-dropdown aria-role="list" class="left-controls">
              <template #trigger>
                <b-button
                  icon-left="columns"
                  :type="
                    columns.filter((item) => item.visible == false).length > 0
                      ? 'is-primary is-light'
                      : ''
                  "
                />
              </template>
              <b-dropdown-item
                v-for="(column, index) in columns"
                :key="index"
                custom
                aria-role="listitem"
              >
                <div class="control">
                  <b-checkbox v-model="column.visible">
                    {{ column.label }}
                  </b-checkbox>
                </div>
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              icon-left="sync"
              @click="loadData"
              class="left-controls"
            />
            <b-select v-model="pageSize" style="display: inline-block">
              <option value="10">10 per page</option>
              <option value="25">25 per page</option>
              <option value="100">100 per page</option>
              <option value="500">500 per page</option>
            </b-select>
            <b-input
              style="max-width: 100px"
              type="number"
              placeholder="Page"
              v-model="currentPageInput"
            ></b-input>
            <div v-if="$route.name === 'ConnectionList'">
              <ClubSelect @input="filterClub" />
            </div>
            <div v-if="$route.name === 'ConnectionList'">
              <PostSelect @input="filterPost" />
            </div>
          </template>
          <b-table-column
            v-for="(column, index) in columns"
            :key="index"
            :field="column.field"
            :label="column.label"
            :width="column.width"
            :numeric="column.numeric"
            :visible="column.visible"
            :sortable="column.sortable"
            :searchable="activeSearch && column.searchable != false"
            :custom-search="column.customSearch"
            :custom-sort="column.customSort"
          >
            <template v-if="column.field == 'actions'" v-slot="props">
              <router-link
                :to="{
                  name: tableViewName + 'View',
                  params: { id: props.row.id },
                }"
              >
                <b-button size="is-small" icon-left="eye"></b-button>
              </router-link>
              <router-link
                v-if="user.role === 'admin'"
                :to="{
                  name: tableViewName + 'Edit',
                  params: { id: props.row.id },
                }"
                ><b-button size="is-small" icon-left="pen"></b-button
              ></router-link>
              <b-button
                v-if="user.role === 'admin'"
                size="is-small"
                icon-left="trash"
                @click="deleteRecord(props.row)"
              ></b-button>
            </template>
            <template v-else-if="column.type == 'church'" v-slot="props">
              {{ props.row[column.field].name_zh }}
              <br />
              {{ props.row[column.field].name_en }}
            </template>
            <template v-else-if="column.type == 'boolean'" v-slot="props">
              <b-icon
                :type="props.row[column.field] ? 'is-success' : 'is-danger'"
                :icon="props.row[column.field] ? 'check' : 'times'"
              >
              </b-icon>
              {{ capitalize(props.row[column.field]) }}
            </template>
            <template v-else-if="column.type == 'user'" v-slot="props">
              <router-link
                :to="{
                  name: 'AdminUserView',
                  params: { id: props.row[column.field].id },
                }"
              >
                {{ props.row[column.field].username }}
              </router-link>
            </template>
            <template v-else v-slot="props">
              {{ props.row[column.field] }}
            </template>

            <template v-if="column.type == 'church'" v-slot:searchable="props">
              <ChurchSelect
                v-model="props.filters[props.column.field]"
                style="font-weight: normal"
              />
            </template>
          </b-table-column>
          <template #detail="props">
            <slot name="detail" v-bind="props"></slot>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.box {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: none;

  /deep/ .level-left {
    flex-wrap: wrap;
    flex-shrink: 1;
  }

  .level-left > button:not(:first-child),
  .level-left > div:not(:first-child) {
    margin-left: 0.25em;
  }

  td[data-label="Actions"] {
    a:not(:first-child),
    button:not(:first-child) {
      margin-left: 0.25em;
    }
  }

  /deep/ .table td {
    vertical-align: middle;
  }
}
</style>

<script>
import JsonCSV from "vue-json-csv";
import stringsMixin from "@/mixins/stringsMixin";
import ChurchSelect from "@/components/ChurchSelect";
import ClubSelect from "@/components/ClubSelect";
import PostSelect from "@/components/PostSelect";
import DeleteRecordModal from "@/components/DeleteRecordModal";
import { mapState } from "vuex";

export default {
  name: "CrudList",
  mixins: [stringsMixin],
  components: {
    JsonCSV,
    ChurchSelect,
    ClubSelect,
    PostSelect,
  },
  props: {
    tableName: String,
    tableNamePlural: String,
    columns: Array,
    dataModifier: {
      type: Function,
      default: (records) => {
        return records;
      },
    },
    defaultSort: String,
    defaultSortDirection: {
      type: String,
      default: "asc",
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    queryParams: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displayRecords: [],
      records: [],
      isLoading: true,
      activeSearch: false,
      pageSize: 25,
      forceCSVCompute: 1,
      visibleData: [],
      currentPage: 1,
      currentPageInput: undefined,
    };
  },
  computed: {
    ...mapState(["user"]),
    tableNameDisplay: function () {
      return this.capitalizeAll(this.tableName);
    },
    tableViewName: function () {
      return this.capitalizeAll(this.tableName).replace(" ", "");
    },
    tableNamePluralDisplay: function () {
      return this.capitalizeAll(this.tableNamePlural);
    },
  },
  watch: {
    currentPageInput: function () {
      this.currentPage = parseInt(this.currentPageInput);
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.axios
        .get(
          "https://ems.awana.org.hk/api/api.php/records/" +
            this.tableName +
            this.queryParams,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.records = this.dataModifier(response.data.records);
          this.displayRecords = this.records;
        });
    },
    toggleSearch() {
      this.activeSearch = !this.activeSearch;
      if (!this.activeSearch) {
        this.$refs.table.filters = {};
      }
    },
    deleteRecord(row) {
      this.$buefy.modal.open({
        component: DeleteRecordModal,
        hasModalCard: true,
        props: {
          recordTable: this.tableName,
          recordData: row,
        },
        events: {
          deleted: () => {
            this.loadData();
          },
        },
      });
    },
    csvCompute() {
      this.visibleData = this.$refs.table.visibleData;
    },
    filterClub(id) {
      if (id === undefined) {
        this.displayRecords = this.records;
        return;
      }
      this.displayRecords = this.records.filter((connection) => {
        return connection.post.filter((post) => {
          if (post.club_id == null) {
            return false;
          }
          return post.club_id.id === id;
        }).length;
      });
    },
    filterPost(posts) {
      if (posts.length === 0) {
        this.displayRecords = this.records;
        return;
      }

      this.displayRecords = this.records.filter((connection) => {
        return connection.post.filter((post) => {
          return posts.filter((postTitle) => {
            // return post.title.includes(postTitle);
            return post.title === postTitle;
          }).length;
        }).length;
      });
    },
  },
};
</script>
