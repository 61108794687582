<template>
  <div>
    <Navbar active="admin_users" />
    <CrudView
      tableName="admin_user"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :showEditButton="false"
    >
      <template>
        <div class="box" style="position: relative">
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            v-if="fetchingLogs"
          ></b-loading>
          <div clss="data-container">
            <span class="data-label">Activities</span>
            <span class="data-content">
              <VueTimeline :events="events" />
            </span>
          </div>
        </div>
      </template>
    </CrudView>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudView from "@/components/CrudView.vue";
import stringsMixin from "@/mixins/stringsMixin";
import VueTimeline from "@gabrielchl/vue-timeline";

export default {
  name: "AdminUserView",
  mixins: [stringsMixin],
  components: {
    Navbar,
    CrudView,
    VueTimeline,
  },
  data() {
    return {
      fetchingLogs: true,
      events: [],
      isLoading: true,
      fields: [
        {
          field: "id",
          label: "ID",
        },
        {
          field: "username",
          label: "Username",
        },
        {
          field: "role",
          label: "Role",
        },
      ],
    };
  },
  mounted() {
    this.fetchingPosts = true;
    this.axios
      .get(
        "https://ems.awana.org.hk/api/api.php/records/admin_log/" +
          "?filter=user_id,eq," +
          this.$route.params.id +
          "&order=id,desc&size=50",
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.events = response.data.records;
        this.events.map((event) => {
          event.title = event.event;
          if (event.event === "page_view") {
            event.icon = "eye";
          } else if (event.event === "auth") {
            event.icon = "lock";
            event.color = "#fd8e00";
          }
          // console.log(JSON.parse(event.content));
          event.content = Object.entries(JSON.parse(event.content)).map(
            ([key, value]) => key + ": " + value
          );
        });
        this.fetchingLogs = false;
      });
  },
  methods: {
    titleRenderer(data) {
      return data.username;
    },
  },
};
</script>
