<template>
  <div>
    <Navbar active="connections" />
    <CrudEdit
      tableName="connection"
      queryParams="?join=church&join=club"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
    >
      <template>
        <div class="box" ref="postBox" style="position: relative">
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            v-if="fetchingChurches || fetchingClubs || fetchingPosts"
          ></b-loading>
          <div class="label">Posts</div>
          <form @submit.prevent="submitPosts">
            <div v-for="(post, index) in posts" :key="index" class="rows">
              <div
                v-if="post.type !== 'delete'"
                class="columns is-variable is-1"
              >
                <div class="column">
                  <ChurchSelect
                    @input="modifyPost(index)"
                    v-model="post.church_id"
                    @selectByIdStart="settingChurches = true"
                    @selectByIdEnd="settingChurches = false"
                  />
                </div>
                <div class="column">
                  <ClubSelect
                    @input="modifyPost(index)"
                    v-model="post.club_id"
                    @selectByIdStart="settingClubs = true"
                    @selectByIdEnd="settingClubs = false"
                  />
                </div>
                <div class="column">
                  <b-input
                    @input="modifyPost(index)"
                    placeholder="Title"
                    v-model="post.title"
                  ></b-input>
                </div>
                <div class="column is-narrow">
                  <b-button
                    @click="removePost(index)"
                    type="is-danger is-light"
                    icon-right="trash"
                  />
                </div>
              </div>
            </div>
            <div class="buttons">
              <b-button @click="addPost" expanded>Add Post</b-button>
              <b-button
                type="is-primary"
                native-type="submit"
                :loading="isSubmitting"
                >Update Posts</b-button
              >
            </div>
          </form>
        </div>
      </template>
    </CrudEdit>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudEdit from "@/components/CrudEdit.vue";
import ChurchSelect from "@/components/ChurchSelect.vue";
import ClubSelect from "@/components/ClubSelect.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ConnectionEdit",
  components: {
    Navbar,
    CrudEdit,
    ChurchSelect,
    ClubSelect,
  },
  data() {
    return {
      fetchingPosts: true,
      isLoading: true,
      posts: [],
      isSubmitting: false,
      settingChurches: false,
      settingClubs: false,
      fields: [
        { field: "name_zh", label: "Name (Chinese)", type: "input" },
        { field: "name_en", label: "Name (English)", type: "input" },
        { field: "name_preferred", label: "Name (Preferred)", type: "input" },
        { field: "title", label: "Title", type: "input" },
        { field: "username", label: "Username", type: "input" },
        { field: "phone", label: "Phone", type: "input" },
        { field: "email", label: "Email", type: "input" },
        { field: "notes", label: "Notes", type: "input" },
      ],
    };
  },
  computed: {
    ...mapState([
      "churches",
      "fetchingChurches",
      "suppressFetchChurches",
      "clubs",
      "fetchingClubs",
      "suppressFetchClubs",
    ]),
  },
  mounted() {
    this.fetchingPosts = true;
    this.axios
      .get(
        "https://ems.awana.org.hk/api/api.php/records/post?filter=connection_id,eq," +
          this.$route.params.id,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.posts = response.data.records;
        this.fetchingPosts = false;
      });
  },
  methods: {
    ...mapActions(["getChurches", "getClubs"]),
    ...mapMutations(["setSuppressFetchChurches", "setSuppressFetchClubs"]),
    titleRenderer(fieldValues) {
      var titleString =
        "Edit Connection: " +
        [fieldValues.name_zh, fieldValues.name_en].join(" ");
      return titleString;
    },
    dataModifier(fieldValues) {
      if (fieldValues.name_zh === "" && fieldValues.name_en === "") {
        return "A connection must at least have a name in Chinese or English";
      }
      return fieldValues;
    },
    addPost() {
      if (this.posts.length > 0) {
        this.setSuppressFetchChurches(Date.now() + 5000);
        this.setSuppressFetchClubs(Date.now() + 5000);
      }
      this.posts.push({
        type: "new",
        church_id: undefined,
        club_id: undefined,
        title: "",
        connection_id: this.$route.params.id,
      });
    },
    removePost(index) {
      if (this.posts[index].type === "new") {
        this.posts.splice(index, 1);
      } else {
        this.$set(this.posts[index], "type", "delete");
      }
    },
    modifyPost(index) {
      if (!this.settingConnections) {
        if (this.posts[index].type !== "new") {
          this.$set(this.posts[index], "type", "edit");
        }
      }
    },
    submitPosts() {
      this.isSubmitting = true;
      var allPromises = [];
      var axiosPromise;
      this.posts.forEach((post) => {
        switch (post.type) {
          case "new":
            if (
              post.church_id === undefined &&
              post.club_id === undefined &&
              post.title === ""
            ) {
              break;
            }
            axiosPromise = this.axios
              .post("https://ems.awana.org.hk/api/api.php/records/post", post, {
                withCredentials: true,
              })
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
            allPromises.push(axiosPromise);
            break;
          case "delete":
            axiosPromise = this.axios
              .delete(
                "https://ems.awana.org.hk/api/api.php/records/post/" + post.id,
                {
                  withCredentials: true,
                }
              )
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
            allPromises.push(axiosPromise);
            break;
          case "edit":
            axiosPromise = this.axios
              .put(
                "https://ems.awana.org.hk/api/api.php/records/post/" + post.id,
                post,
                {
                  withCredentials: true,
                }
              )
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
            allPromises.push(axiosPromise);
            break;
        }
      });
      Promise.all(allPromises).then(() => {
        this.$router.push({
          name: "ConnectionView",
          params: { id: this.$route.params.id },
        });
      });
      this.isSubmitting = false;
    },
  },
};
</script>
