<template>
  <div>
    <Navbar active="churches" />
    <CrudList
      tableName="church"
      tableNamePlural="churches"
      defaultSort="id"
      :columns="columns"
      :dataModifier="dataModifier"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudList from "@/components/CrudList.vue";
import stringsMixin from "@/mixins/stringsMixin";

export default {
  name: "ChurchList",
  mixins: [stringsMixin],
  components: {
    Navbar,
    CrudList,
  },
  data() {
    return {
      columns: [
        {
          field: "id",
          label: "ID",
          sortable: true,
          numeric: true,
          visible: true,
          width: "60px",
        },
        {
          field: "name_zh",
          label: "Name (Chinese)",
          sortable: true,
          visible: true,
        },
        {
          field: "name_en",
          label: "Name (English)",
          sortable: true,
          visible: true,
        },
        { field: "addr", label: "Address", sortable: true, visible: false },
        {
          field: "district",
          label: "District",
          sortable: true,
          visible: false,
        },
        { field: "phone", label: "Phone", sortable: false, visible: true },
        { field: "email", label: "Email", sortable: false, visible: false },
        {
          field: "actions",
          label: "Actions",
          sortable: false,
          visible: true,
          searchable: false,
          width: "140px",
        },
      ],
    };
  },
  methods: {
    dataModifier(records) {
      records.map((record) => {
        if (!record.district) {
          console.log(record);
          return;
        }
        record.district = record.district
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.substr(1))
          .join(" ");
      });
      return records;
    },
  },
};
</script>
