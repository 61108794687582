import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    connections: {},
    fetchingConnections: false,
    suppressFetchConnections: 0,
    churches: [],
    fetchingChurches: false,
    suppressFetchChurches: 0,
    clubs: [],
    fetchingClubs: false,
    suppressFetchClubs: 0,
  },
  plugins: [createPersistedState()],
  mutations: {
    setUser(state, user) {
      Vue.set(state, "user", user);
    },
    setConnections(state, connection) {
      Vue.set(state, "connections", connection);
    },
    setFetchingConnections(state, status) {
      Vue.set(state, "fetchingConnections", status);
    },
    setSuppressFetchConnections(state, status) {
      Vue.set(state, "suppressFetchConnections", status);
    },
    setChurches(state, connection) {
      Vue.set(state, "churches", connection);
    },
    setFetchingChurches(state, status) {
      Vue.set(state, "fetchingChurches", status);
    },
    setSuppressFetchChurches(state, status) {
      Vue.set(state, "suppressFetchChurches", status);
    },
    setClubs(state, connection) {
      Vue.set(state, "clubs", connection);
    },
    setFetchingClubs(state, status) {
      Vue.set(state, "fetchingClubs", status);
    },
    setSuppressFetchClubs(state, status) {
      Vue.set(state, "suppressFetchClubs", status);
    },
  },
  actions: {
    getConnections({ commit, state }) {
      if (Date.now() < state.suppressFetchConnections) {
        return;
      }
      if (!state.fetchingConnections) {
        commit("setFetchingConnections", true);
        axios
          .get(
            "https://ems.awana.org.hk/api/api.php/records/connection?join=club,church&join=church",
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            // response.data.records.reduce((acc,curr)=> (acc[curr.connection_id.id]=curr,acc),{});
            // console.log(response.data.records.reduce((array, connection) => { array[connection.id] = array }));
            // commit("setConnections", response.data.records.reduce((array, connection) => { (array[connection.id] = connection, array), {} }));
            console.log(
              Object.assign(
                ...response.data.records.map((connection) => ({
                  [connection.id]: connection,
                }))
              )
            );
            commit(
              "setConnections",
              Object.assign(
                ...response.data.records.map((connection) => ({
                  [connection.id]: connection,
                }))
              )
            );
            commit("setFetchingConnections", false);
          });
      }
      setTimeout(function () {
        commit("setFetchingConnections", false);
      }, 5000);
    },
    getChurches({ commit, state }) {
      if (Date.now() < state.suppressFetchChurches) {
        return;
      }
      if (!state.fetchingChurches) {
        commit("setFetchingChurches", true);
        axios
          .get("https://ems.awana.org.hk/api/api.php/records/church", {
            withCredentials: true,
          })
          .then((response) => {
            commit("setChurches", response.data.records);
            commit("setFetchingChurches", false);
          });
      }
      setTimeout(function () {
        commit("setFetchingChurches", false);
      }, 5000);
    },
    getClubs({ commit, state }) {
      if (Date.now() < state.suppressFetchClubs) {
        return;
      }
      if (!state.fetchingClubs) {
        commit("setFetchingClubs", true);
        axios
          .get("https://ems.awana.org.hk/api/api.php/records/club", {
            withCredentials: true,
          })
          .then((response) => {
            commit("setClubs", response.data.records);
            commit("setFetchingClubs", false);
          });
      }
      setTimeout(function () {
        commit("setFetchingClubs", false);
      }, 5000);
    },
  },
  modules: {},
});
