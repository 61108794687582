<template>
  <div>
    <Navbar active="connections" />
    <CrudNew
      tableName="connection"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
      :readyForReload="readyForReload"
      @beforeReload="submitPosts"
    >
      <template>
        <div class="box" style="position: relative">
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            v-if="fetchingChurches || fetchingClubs"
          ></b-loading>
          <div class="label">Posts</div>
          <form @submit.prevent="submitPosts">
            <div v-for="(post, index) in posts" :key="index" class="rows">
              <div class="columns is-variable is-1">
                <div class="column">
                  <ChurchSelect v-model="post.church_id" />
                </div>
                <div class="column">
                  <ClubSelect v-model="post.club_id" />
                </div>
                <div class="column">
                  <b-input placeholder="Title" v-model="post.title"></b-input>
                </div>
                <div class="column is-narrow">
                  <b-button
                    @click="removePost(index)"
                    type="is-danger is-light"
                    icon-right="trash"
                  />
                </div>
              </div>
            </div>
            <div class="buttons">
              <b-button @click="addPost" expanded>Add Post</b-button>
            </div>
          </form>
        </div>
      </template>
    </CrudNew>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudNew from "@/components/CrudNew.vue";
import ChurchSelect from "@/components/ChurchSelect.vue";
import ClubSelect from "@/components/ClubSelect.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ConnectionNew",
  components: {
    Navbar,
    CrudNew,
    ChurchSelect,
    ClubSelect,
  },
  data() {
    return {
      readyForReload: false,
      isLoading: true,
      posts: [],
      isSubmitting: false,
      fields: [
        { field: "name_zh", label: "Name (Chinese)", type: "input" },
        { field: "name_en", label: "Name (English)", type: "input" },
        { field: "name_preferred", label: "Name (Preferred)", type: "input" },
        { field: "title", label: "Title", type: "input" },
        { field: "username", label: "Username", type: "input" },
        { field: "phone", label: "Phone", type: "input" },
        { field: "email", label: "Email", type: "input" },
        { field: "notes", label: "Notes", type: "input" },
      ],
    };
  },
  computed: {
    ...mapState([
      "churches",
      "fetchingChurches",
      "suppressFetchChurches",
      "clubs",
      "fetchingClubs",
      "suppressFetchClubs",
    ]),
  },
  methods: {
    ...mapActions(["getChurches", "getClubs"]),
    ...mapMutations(["setSuppressFetchChurches", "setSuppressFetchClubs"]),
    titleRenderer(fieldValues) {
      return (
        "Add Connection: " +
        [fieldValues.name_zh, fieldValues.name_en].join(" ")
      );
    },
    dataModifier(fieldValues) {
      if (fieldValues.name_zh === "" && fieldValues.name_en === "") {
        return "A connection must at least have a name in Chinese or English";
      }
      return fieldValues;
    },
    addPost() {
      if (this.posts.length > 0) {
        this.setSuppressFetchChurches(Date.now() + 5000);
        this.setSuppressFetchClubs(Date.now() + 5000);
      }
      this.posts.push({
        church_id: undefined,
        club_id: undefined,
        title: "",
        connection_id: this.$route.params.id,
      });
    },
    removePost(index) {
      this.posts.splice(index, 1);
    },
    submitPosts(connectionId) {
      this.isSubmitting = true;
      var allPromises = [];
      var axiosPromise;
      this.posts.forEach((post) => {
        if (
          post.church_id === undefined &&
          post.club_id === undefined &&
          post.title === ""
        ) {
          return;
        }
        post.connection_id = connectionId;
        axiosPromise = this.axios
          .post("https://ems.awana.org.hk/api/api.php/records/post", post, {
            withCredentials: true,
          })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
        allPromises.push(axiosPromise);
      });
      Promise.all(allPromises).then(() => {
        this.readyForReload = true;
      });
      this.isSubmitting = false;
    },
  },
};
</script>
