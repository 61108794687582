<template>
  <div>
    <Navbar active="trainings" />
    <CrudNew
      tableName="training"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
      :readyForReload="readyForReload"
      @beforeReload="submitEnrollments"
    >
      <template>
        <div class="box" style="position: relative">
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            v-if="fetchingConnections"
          ></b-loading>
          <div class="label">Enrollments</div>
          <form @submit.prevent="submitEnrollments">
            <div
              v-for="(enrollment, index) in enrollments"
              :key="index"
              class="rows"
            >
              <div class="columns is-variable is-1">
                <div class="column">
                  <ConnectionSelect v-model="enrollment.connection_id" />
                  <!-- {{ enrollment.connection_id }} -->
                </div>
                <div class="column">
                  <b-input
                    placeholder="Result"
                    v-model="enrollment.result"
                  ></b-input>
                </div>
                <div class="column">
                  <b-input
                    placeholder="Notes"
                    v-model="enrollment.notes"
                  ></b-input>
                </div>
                <div class="column is-narrow">
                  <b-button
                    @click="removeEnrollment(index)"
                    type="is-danger is-light"
                    icon-right="trash"
                  />
                </div>
              </div>
            </div>
            <div class="buttons">
              <b-button @click="addEnrollment" expanded
                >Add Enrollment</b-button
              >
            </div>
          </form>
        </div>
      </template>
    </CrudNew>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudNew from "@/components/CrudNew.vue";
import ConnectionSelect from "@/components/ConnectionSelect.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "TrainingNew",
  components: {
    Navbar,
    CrudNew,
    ConnectionSelect,
  },
  data() {
    return {
      readyForReload: false,
      isLoading: true,
      enrollments: [],
      isSubmitting: false,
      fields: [
        { field: "course_code", label: "Code", type: "input" },
        { field: "course_title", label: "Title", type: "input" },
        { field: "course_date", label: "Date", type: "input" },
        { field: "course_level", label: "Level", type: "input" },
      ],
    };
  },
  computed: {
    ...mapState([
      "connections",
      "fetchingConnections",
      "suppressFetchConnections",
    ]),
  },
  methods: {
    ...mapActions(["getConnections"]),
    ...mapMutations(["setSuppressFetchConnections"]),
    titleRenderer(fieldValues) {
      var titleString =
        "New Training: " +
        [fieldValues.course_code, fieldValues.course_title].join(" ");
      return titleString;
    },
    dataModifier(fieldValues) {
      if (fieldValues.course_code === "") {
        return "A training must at least have a course code";
      }
      return fieldValues;
    },
    addEnrollment() {
      if (this.enrollments.length > 0) {
        this.setSuppressFetchConnections(Date.now() + 5000);
      }
      this.enrollments.push({
        connection_id: undefined,
        notes: "",
        result: "",
        training_id: this.$route.params.id,
      });
    },
    removeEnrollment(index) {
      this.enrollments.splice(index, 1);
    },
    submitEnrollments(trainingId) {
      this.isSubmitting = true;
      var allPromises = [];
      var axiosPromise;
      this.enrollments.forEach((enrollment) => {
        enrollment.training_id = trainingId;
        axiosPromise = this.axios
          .post(
            "https://ems.awana.org.hk/api/api.php/records/enrollment",
            enrollment,
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
        allPromises.push(axiosPromise);
      });
      Promise.all(allPromises).then(() => {
        this.readyForReload = true;
      });
      this.isSubmitting = false;
    },
  },
};
</script>
