<template>
  <div class="section">
    <div class="container">
      <h1 v-html="titleRenderer(fieldValues)"></h1>
      <div class="columns">
        <div class="column">
          <div class="box">
            <form @submit.prevent="submitForm">
              <b-field
                v-for="field in fields"
                :label="field.label"
                :key="field.field"
              >
                <DistrictSelect
                  v-if="field.type == 'district'"
                  v-model="fieldValues[field.field]"
                />
                <ChurchSelect
                  v-else-if="field.type == 'church'"
                  v-model="fieldValues[field.field]"
                />
                <b-switch
                  v-else-if="field.type == 'switch'"
                  v-model="fieldValues[field.field]"
                >
                  {{ capitalize(fieldValues[field.field]) }}
                </b-switch>
                <b-input v-else v-model="fieldValues[field.field]"></b-input>
              </b-field>
              <router-link
                :to="{
                  name: tableNameDisplay + 'View',
                  params: { id: $route.params.id },
                }"
                style="margin-right: 5px"
              >
                <b-button>Cancel and Return</b-button>
              </router-link>
              <b-button
                type="is-primary"
                native-type="submit"
                :loading="isSubmitting"
                >Update {{ tableNameDisplay }}</b-button
              >
            </form>
          </div>
        </div>
        <div class="column"><slot></slot></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.box {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: none;

  .rows:not(:last-of-type) .columns {
    margin-bottom: 0;
  }
}

/deep/ .label {
  display: block;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 4px;
}

/deep/ .field:not(:last-child) {
  margin-bottom: 20px;
}
</style>

<script>
import DistrictSelect from "@/components/DistrictSelect.vue";
import ChurchSelect from "@/components/ChurchSelect.vue";
// import ConnectionSelect from "@/components/ConnectionSelect.vue";
import stringsMixin from "@/mixins/stringsMixin";

export default {
  name: "CrudEdit",
  mixins: [stringsMixin],
  components: {
    DistrictSelect,
    ChurchSelect,
    // ConnectionSelect,
  },
  props: {
    tableName: String,
    fields: Array,
    titleRenderer: Function,
    dataModifier: Function,
    queryParams: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      apiResponse: {},
      isSubmitting: false,
      fieldValues: {},
    };
  },
  computed: {
    tableNameDisplay: function () {
      return this.capitalize(this.tableName);
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const loadingComponent = this.$buefy.loading.open();
      this.axios
        .get(
          "https://ems.awana.org.hk/api/api.php/records/" +
            this.tableName +
            "/" +
            this.$route.params.id +
            this.queryParams,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          this.apiResponse = response.data;
          loadingComponent.close();
          this.fields.forEach((field) => {
            this.$set(
              this.fieldValues,
              field.field,
              response.data[field.field]
            );
          });
        });
    },
    submitForm() {
      var fieldValuesSubmit = this.dataModifier(this.fieldValues);
      if (typeof fieldValuesSubmit === "string") {
        this.$buefy.snackbar.open({
          message: fieldValuesSubmit,
          type: "is-danger",
        });
        return;
      }

      this.isSubmitting = true;
      this.axios
        .put(
          "https://ems.awana.org.hk/api/api.php/records/" +
            this.tableName +
            "/" +
            this.$route.params.id,
          fieldValuesSubmit,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          this.$router.push({
            name: this.tableNameDisplay + "View",
            params: { id: this.$route.params.id },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
