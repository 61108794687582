var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navbar',{attrs:{"active":"trainings"}}),_c('CrudView',{attrs:{"tableName":"training","isHorizontal":true,"fields":_vm.fields,"titleRenderer":_vm.titleRenderer,"dataModifier":_vm.dataModifier}},[[_c('div',{staticClass:"box",staticStyle:{"position":"relative"}},[(_vm.fetchingEnrollments)?_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}):_vm._e(),_c('div',{attrs:{"clss":"data-container"}},[_c('span',{staticClass:"data-label"},[_vm._v("Enrollments")]),_c('b-table',{attrs:{"data":_vm.enrollments},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("No records")])]},proxy:true}])},[_c('b-table-column',{attrs:{"field":"result","label":"Result","sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.result)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"connection_id.name_zh","label":"Name (Chinese)","sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
                  name: 'ConnectionView',
                  params: { id: props.row.connection_id.id },
                }}},[_vm._v(" "+_vm._s(props.row.connection_id.name_zh)+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"connection_id.name_en","label":"Name (English)","sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
                  name: 'ConnectionView',
                  params: { id: props.row.connection_id.id },
                }}},[_vm._v(" "+_vm._s(props.row.connection_id.name_en)+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"post","label":"Post"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._l((props.row.connection_id.post),function(post){return [(post.club_id !== undefined && post.club_id !== null)?_c('p',{key:post.id},[_vm._v(" "+_vm._s(post.title)+" @ "),_c('router-link',{attrs:{"to":{
                      name: 'ClubView',
                      params: { id: post.club_id.id },
                    }}},[_vm._v(" "+_vm._s(post.club_id.reg_num)+" "+_vm._s(post.club_id.name_zh)+" ")])],1):(
                    post.church_id !== undefined && post.church_id !== null
                  )?_c('p',{key:post.id},[_vm._v(" "+_vm._s(post.title)+" @ "),_c('router-link',{attrs:{"to":{
                      name: 'ChurchView',
                      params: { id: post.church_id.id },
                    }}},[_vm._v(" "+_vm._s(post.church_id.name_zh)+" ")])],1):_vm._e()]})]}}])})],1)],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }