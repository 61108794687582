<template>
  <div>
    <Navbar active="connections" />
    <CrudList
      tableName="connection"
      tableNamePlural="connections"
      defaultSort="name_zh"
      queryParams="?join=post,church&join=post,club"
      :columns="columns"
      :dataModifier="dataModifier"
    >
      <template #detail="props">
        <div v-for="post in props.row.post" :key="post.id">
          {{ post.title }} @
          <template v-if="post.club_id">
            <router-link
              :to="{ name: 'ClubView', params: { id: post.club_id.id } }"
              >{{ post.club_id.reg_num }} {{ post.club_id.name_zh }}
              {{ post.club_id.name_en }}</router-link
            >
          </template>
          <template v-else>
            <router-link
              :to="{ name: 'ChurchView', params: { id: post.church_id.id } }"
              >{{ post.church_id.name_zh }}
              {{ post.church_id.name_en }}</router-link
            >
          </template>
        </div>
        <div v-if="props.row.post.length === 0">No posts found</div>
      </template>
    </CrudList>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudList from "@/components/CrudList.vue";

export default {
  name: "ConnectionList",
  components: {
    Navbar,
    CrudList,
  },
  data() {
    return {
      columns: [
        {
          field: "name_zh",
          label: "Name (Chinese)",
          type: "input",
          sortable: true,
          visible: true,
        },
        {
          field: "name_en",
          label: "Name (English)",
          type: "input",
          sortable: true,
          visible: true,
        },
        {
          field: "name_preferred",
          label: "Name (Preferred)",
          type: "input",
          sortable: true,
          visible: false,
        },
        {
          field: "title",
          label: "Title",
          type: "input",
          sortable: true,
          visible: true,
        },
        {
          field: "username",
          label: "Username",
          type: "input",
          sortable: true,
          visible: false,
        },
        {
          field: "phone",
          label: "Phone",
          type: "input",
          sortable: false,
          visible: true,
        },
        {
          field: "email",
          label: "Email",
          type: "input",
          sortable: false,
          visible: true,
        },
        {
          field: "notes",
          label: "Notes",
          type: "input",
          sortable: false,
          visible: false,
        },
        {
          field: "actions",
          label: "Actions",
          sortable: false,
          visible: true,
          searchable: false,
          width: "140px",
        },
      ],
    };
  },
  methods: {
    dataModifier(records) {
      return records;
    },
  },
};
</script>
