<template>
  <div>
    <Navbar />
    <div class="section">
      <div class="container is-max-desktop page-login">
        <div class="columns is-centered">
          <div class="column is-half">
            <span class="login-title">Login</span>
            <form @submit.prevent>
              <b-field label="Username">
                <b-input v-model="username"></b-input>
              </b-field>
              <b-field label="Password">
                <b-input
                  type="password"
                  v-model="password"
                  password-reveal
                ></b-input>
              </b-field>
              <b-button native-type="submit" type="is-primary" @click="login"
                >Login</b-button
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.login-title {
  font-weight: bold;
  font-size: 20px;
}

.field:first-of-type {
  margin-top: 20px;
}
</style>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState, mapMutations } from "vuex";
import logsMixin from "@/mixins/logsMixin.js";

export default {
  name: "Login",
  mixins: [logsMixin],
  components: {
    Navbar,
  },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["setUser"]),
    login() {
      this.axios
        .post(
          "https://ems.awana.org.hk/api/api.php/login",
          {
            username: this.username,
            password: this.password,
          },
          {
            withCredentials: true,
            // crossDomain: true
            // headers: {
            //   'Content-Type': 'application/json',
            //   'Access-Control-Origin': '*',
            //   Accept: 'application/json, text/plain, */*',
            //   'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
            //   'Access-Control-Allow-Credentials': true
            // }
          }
        )
        .then((response) => {
          response = response.data;
          if ("id" in response && "username" in response) {
            console.log(response);
            this.setUser(response);
            this.log("auth", {
              type: "login",
            });
            this.$router.push({ name: "Dashboard" });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$buefy.snackbar.open({
            message: "Failed to login",
            type: "is-danger",
          });
        });
    },
  },
};
</script>
