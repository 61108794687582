<template>
  <div>
    <Navbar active="admin_logs" />
    <CrudList
      tableName="admin_log"
      tableNamePlural="admin_logs"
      queryParams="?join=admin_user&order=id,desc&size=250"
      defaultSort="id"
      defaultSortDirection="desc"
      :showAddButton="false"
      :columns="columns"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudList from "@/components/CrudList.vue";
import stringsMixin from "@/mixins/stringsMixin";

export default {
  name: "AdminLogList",
  mixins: [stringsMixin],
  components: {
    Navbar,
    CrudList,
  },
  data() {
    return {
      columns: [
        {
          field: "id",
          label: "ID",
          sortable: true,
          numeric: true,
          visible: false,
          width: "60px",
        },
        {
          field: "timestamp",
          label: "Timestamp",
          sortable: true,
          visible: true,
        },
        {
          field: "user_id",
          label: "User ID",
          type: "user",
          sortable: true,
          visible: true,
        },
        {
          field: "event",
          label: "Event",
          sortable: true,
          visible: true,
        },
        { field: "content", label: "Content", sortable: false, visible: true },
      ],
    };
  },
};
</script>
