<template>
  <div>
    <Navbar active="connections" />
    <CrudView
      tableName="connection"
      :fields="fields"
      :titleRenderer="titleRenderer"
      :dataModifier="dataModifier"
    >
      <template>
        <div class="box" style="position: relative">
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            v-if="fetchingPosts || fetchingEnrollments"
          ></b-loading>
          <div clss="data-container">
            <span class="data-label">Posts</span>
            <div class="data-content">
              <div v-for="post in posts" :key="post.id">
                <template v-if="post.club_id != undefined">
                  {{ post.title }} @
                  <router-link
                    :to="{
                      name: 'ClubView',
                      params: { id: post.club_id.id },
                    }"
                    v-bind:key="post.id"
                  >
                    {{ post.club_id.reg_num }}
                  </router-link>
                </template>
                <template v-else-if="post.church_id != undefined">
                  {{ post.title }} @
                  <router-link
                    :to="{
                      name: 'ChurchView',
                      params: { id: post.church_id.id },
                    }"
                    v-bind:key="post.id"
                  >
                    {{ post.church_id.name_zh }} {{ post.church_id.name_en }}
                  </router-link>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div clss="data-container">
            <span class="data-label">Enrollments</span>
            <div class="data-content">
              <b-steps
                v-model="trainingProgress"
                :has-navigation="false"
                mobile-mode="compact"
              >
                <b-step-item
                  step="0"
                  value="0"
                  :clickable="false"
                ></b-step-item>
                <b-step-item
                  step="1"
                  value="1"
                  :clickable="false"
                ></b-step-item>
                <b-step-item
                  step="2"
                  value="2"
                  :clickable="false"
                ></b-step-item>
                <b-step-item
                  step="3"
                  value="3"
                  :clickable="false"
                ></b-step-item>
              </b-steps>

              <div v-for="enrollment in enrollments" :key="enrollment.id">
                {{ enrollment.result }} @
                <router-link
                  :to="{
                    name: 'TrainingView',
                    params: { id: enrollment.training_id.id },
                  }"
                >
                  {{ enrollment.training_id.course_code }}
                  {{ enrollment.training_id.course_title }} ({{
                    enrollment.training_id.course_date
                  }})
                </router-link>
                <template v-if="enrollment.training_id.course_level">
                  <b-tag type="is-primary">{{
                    enrollment.training_id.course_level
                  }}</b-tag>
                </template>
                <template v-if="enrollment.notes != ''">
                  ({{ enrollment.notes }})
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CrudView>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CrudView from "@/components/CrudView.vue";

export default {
  name: "ConnectionView",
  components: {
    Navbar,
    CrudView,
  },
  data() {
    return {
      fetchingPosts: true,
      fetchingEnrollments: true,
      posts: [],
      enrollments: [],
      trainingProgress: 0,
      isLoading: true,
      fields: [
        { field: "id", label: "ID" },
        { field: "name_zh", label: "Name (Chinese)", type: "input" },
        { field: "name_en", label: "Name (English)", type: "input" },
        { field: "name_preferred", label: "Name (Preferred)", type: "input" },
        { field: "title", label: "Title", type: "input" },
        { field: "username", label: "Username", type: "input" },
        { field: "phone", label: "Phone", type: "input" },
        { field: "email", label: "Email", type: "input" },
        { field: "notes", label: "Notes", type: "input" },
      ],
    };
  },
  mounted() {
    this.fetchingPosts = true;
    this.fetchingEnrollments = true;
    this.axios
      .get(
        "https://ems.awana.org.hk/api/api.php/records/connection/" +
          this.$route.params.id +
          "?join=post,church&join=post,club&join=enrollment,training",
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.posts = response.data.post;
        this.enrollments = response.data.enrollment;
        this.fetchingPosts = false;
        this.fetchingEnrollments = false;

        this.enrollments.forEach((enrollment) => {
          const training = enrollment.training_id;
          if (
            training.course_level !== undefined ||
            training.course_level !== ""
          ) {
            const courseLevel = Number(training.course_level.trim().charAt(0));
            if (
              this.trainingProgress === undefined ||
              courseLevel > this.trainingProgress
            ) {
              this.trainingProgress = courseLevel;
            }
          }
        });
      });
  },
  methods: {
    titleRenderer(data) {
      return [data.name_zh, data.name_en].join(" ");
    },
    dataModifier(data) {
      return data;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .step-items {
  margin-top: 8px;
}

/deep/ .b-steps .steps + .step-content {
  padding-top: 0;
}
</style>
