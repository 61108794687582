export default {
  methods: {
    recordLabel(table, record, full = false, linked = false) {
      switch (table) {
        case "church":
          return record.name_zh + " " + record.name_en;
        case "club":
          return record.reg_num + " " + record.name_zh + " " + record.name_en;
        case "connection":
          return record.name_zh + " " + record.name_en;
        case "post": {
          let label = "";
          if (full) {
            label +=
              record.connection_id.name_zh +
              " " +
              record.connection_id.name_en +
              " @ ";
          }
          label += record.title + " @ ";
          if (record.club_id != null) {
            label +=
              record.club_id.reg_num +
              " " +
              record.club_id.name_zh +
              " " +
              record.club_id.name_en;
          } else if (record.church_id != null) {
            label += record.church_id.name_zh + " " + record.church_id.name_en;
          }
          return label;
        }
        case "training":
          return record.course_code + " " + record.course_title; // figure out how to add course level here, html'ly
        case "enrollment": {
          let label = "";
          if (full) {
            label +=
              record.connection_id.name_zh +
              " " +
              record.connection_id.name_en +
              " @ ";
          }
          return (
            label +
            record.result +
            " @ " +
            record.training_id.course_code +
            " " +
            record.training_id.course_title
          );
        }
      }

      return ""; // table name unknown
    },
  },
};
