export default {
  methods: {
    log(eventName, content) {
      if (process.env.NODE_ENV === "development") {
        return;
      }
      if (typeof content === "object") {
        content = JSON.stringify(content);
      }
      console.log(this.user.id);
      this.axios
        .post(
          "https://ems.awana.org.hk/api/api.php/records/admin_log",
          {
            user_id: this.user.id,
            event: eventName,
            content: content,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
