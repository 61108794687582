import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Buefy from "buefy";
import "buefy/dist/buefy.css";
// import '@/assets/buefy.scss'
import VueGtag from "vue-gtag";

import axios from "axios";
import "./registerServiceWorker";

Vue.prototype.axios = axios;
Vue.prototype.axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.commit("setUser", {});
      router.push({ name: "Login" });
    }
    return Promise.reject(error);
  }
);

Vue.use(Buefy, {
  defaultIconPack: "fas",
});

Vue.use(
  VueGtag,
  {
    config: { id: "G-P0Z2VS54YJ" },
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
